<div class="aura-sub-container-custom content-area">
    <div class="shelve d-flex gap-3">
        <ng-container *ngIf="!isLoading && recommendBrand && recommendBrand.data.length > 0">
            <div class="card-box-position" *ngFor="let brand of recommendBrand.data; let i = index"
                [attr.data-cy-row-number]="i + 1">
                <app-card-brand [item]="brand" (clickBrand)="onClickBrand($event)" />
                <!-- <app-card-item-product class="w-fit" [item]="item" /> -->
            </div>
            <div class="no-data-section" *ngIf="recommendBrand && recommendBrand.data.length === 0">
                <img src="assets/icons/Not_Found_Item_Icon.svg" />
            </div>
        </ng-container>

        <div class="no-data-section" *ngIf="!isLoading && recommendBrand && recommendBrand.data.length === 0">
            <img src="assets/icons/Not_Found_Item_Icon.svg" />
        </div>

        <div class="loading-section" *ngIf="isLoading">
            <div class="spinner-border"></div>
        </div>
    </div>
</div>