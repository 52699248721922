import { Component, OnInit } from '@angular/core';
import { APP_NAME } from '@helpers/app.constants';
import { Title } from '@angular/platform-browser';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
// import { SwPush } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private afMessaging: AngularFireMessaging,private titleService: Title) { }

  ngOnInit(): void {
    if (!this.ignoreReceiveMessage()) {
      // this.requestPermission();
      this.receiveMessage();
      this.titleService.setTitle(APP_NAME);
    }
  }

  ignoreReceiveMessage(): boolean {
    return window.navigator.userAgent.includes('aura-native-ios');
  }

  // requestPermission() {
  //   this.afMessaging.requestToken.subscribe(
  //     (token) => {
  //       console.error('Permission granted! Save to the server!', token);
  //     },
  //     (error) => {
  //       console.error(error);
  //     }
  //   );
  // }

  // TODO: Should be refactor this code
  receiveMessage() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.afMessaging.messages.subscribe((payload: any) => {
      console.error('new message received. ', payload);
    });
  }
}
