<button (click)="onClickBrand(item.id)" *ngIf="item" class="btn-cursor">
    <img [ngClass]="isLandigPage ? 'poster-landing-page' :'poster'" [src]="imagePath"
        onerror="this.src='assets/images/image_error.jpg'" />
    <div class="">
        <p *ngIf="!isLandigPage">{{ item.name }}</p>
        <div class="d-flex justify-content-between describe">
            <!-- <p>{{ 4.8 }} กม.</p> -->
            <!-- <p>
                <img
                    class="rateIcon"
                    src="assets/icons/star_fill.svg"
                    width="16px"
                    alt=""
                />{{ 4.5 }}
            </p> -->
        </div>
    </div>
</button>