import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PurchaseOrderService } from '@core/services/retailer/purchase.service';
import { Router } from '@angular/router';
// import { IItem } from '@core/models/retailer/cart.model';
import { reOrderProducts } from '@core/models/retailer/purchase-order.model';
import { IItemProductPayload } from '@core/models/retailer/cart.model';
interface ErrorResponse {
  error: string | reOrderProducts[]
}

interface ProductReorder {
  productId: number;
  price: number;
  stockistProductId: number;
  quantity: number;
}
@Component({
  selector: 'app-reorder-modal',
  templateUrl: './reorder-modal.component.html',
  styleUrls: ['./reorder-modal.component.scss']
})
export class ReOrderModalComponent {
  @Input() isOpenModal!: boolean;
  @Input() orderNumber!: string;
  @Input() products: reOrderProducts[] = [];
  @Input() currentOrderStatus = ''
  @Output() isOpenModalChange = new EventEmitter<boolean>();
  reasonChecked = [false, false, false, false, false, false];
  isFormValid = false;
  otherReason = '';
  showError = false;
  reason5Checked = false;
  formattedReason = {};

  reasonForm: FormGroup;

  constructor(private fb: FormBuilder, private purchaseOrderService: PurchaseOrderService, private router: Router) {
    this.reasonForm = this.fb.group({
      reason: ['', Validators.required]
    });
  }

    close() {
      this.isOpenModal = false;
      this.isOpenModalChange.emit(this.isOpenModal);
    }

    addToCart() {
      let product: ProductReorder = {
        productId: 0,
        price: 0,
        stockistProductId: 0,
        quantity: 0
      }

      const products: IItemProductPayload[] =[]

      const data = {
        confirm: true
      }

      this.purchaseOrderService.reOrderPurchase(this.orderNumber, data).subscribe({
        next: (res) => {
          for (const item of res) {
            if (!item.valid) {
              product = {
                productId: item.productId,
                stockistProductId: item.stockistProductId,
                price: item.price,
                quantity: item.quantity
              }

              products.push(product)
            }

          }

          localStorage.setItem('productData', JSON.stringify(products));

          this.router.navigate(['/retailer/my-cart'])
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        error: (error: ErrorResponse) => {
          console.error(error)

        },
    });
    }

  }
