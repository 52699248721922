<div class="ship-to-address-detail">
    <img
            class="mr-3"
            src="assets/images/shipaddress.svg"
            width="20px"
            height="20px"
            alt=""
          />
    <div class="ml-2">
        <div class="d-flex justify-content-between align-items-end">
            <div class="text-header fs-sm">{{ 'TITLE.DELIVERED_AT' | translate }}</div>
            <div *ngIf="isShowLocation" class="text-view-map fw-semibold">
                <img class="map-size" [src]="mapImage" alt="map-icon" />
                {{ 'TITLE.MAP' | translate }}
            </div>
        </div>
        <div class="fs-6 fw-normal text-name mt-2">
            {{ name }}
        </div>
        <div class="fs-sm fw-normal text-address">
            {{ address }}
        </div>
    </div>
</div>
