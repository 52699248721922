import { Component } from '@angular/core';
import { TranslationService } from '@modules/i18n';
import { TranslateService } from '@ngx-translate/core';
import { locale as enLang } from './../../modules/i18n/vocabs/en';
import { locale as thLang } from './../../modules/i18n/vocabs/th';
@Component({
  selector: 'app-layout-retailer',
  templateUrl: './layout-retailer.component.html',
  styleUrls: ['./layout-retailer.component.scss']
})
export class LayoutRetailerComponent {
  constructor(
    private translationService: TranslationService,
    private translate: TranslateService,
  ) {
      // const lang = this.translationService.getLanguage();
      // this.translate.use(lang);
      // register translations
      this.translationService.loadTranslations(enLang, thLang);
  }
}
