/* eslint-disable @typescript-eslint/no-explicit-any */
import { PurchaseOrder, PurchaseOrderImpl } from './purchase-order.model';

export class CreateRetailerPurchaseOrderResponse {
  retailerPurchaseOrderNumber: string;

  constructor(data: any) {
    this.retailerPurchaseOrderNumber = data.retailerPurchaseOrderNumber;
  }
}

export class RetailerPurchaseOrder {
  id: string;
  retailerPurchaseOrderNumber: string;
  retailerName: string;
  retailerAddress: string;
  createdAt: string;
  totalAmount: number;
  shippingCost: number;
  couponDiscount: number;
  netAmount: number;
  total_quantity_all: number;
  orders: PurchaseOrder[];
  promotionDiscount: number;
  totalAfterPromotionDiscount: number;


  constructor(data: any) {
    this.id = data.id;
    this.retailerPurchaseOrderNumber = data.retailerPurchaseOrderNumber;
    this.retailerName = data.retailerName;
    this.retailerAddress = data.retailerAddress;
    this.createdAt = data.createdAt;
    this.totalAmount = data.totalAmount;
    this.shippingCost = data.shippingCost;
    this.couponDiscount = data.discount;
    this.netAmount = data.netAmount;
    this.total_quantity_all = data.total_quantity_all;
    this.orders = data.orders?.map(
      (x: PurchaseOrder) => new PurchaseOrderImpl(x)
    );
    this.promotionDiscount = data.promotionDiscount;
    this.totalAfterPromotionDiscount = data.totalAfterPromotionDiscount;
  }
}

export class RetailerPurchaseOrderHistory {
  retailerPurchaseOrderNumber: string;
  createdDate: string;
  summaryAmount: number;
  retailerPurchaseOrderHistoryDetails: RetailerPurchaseOrderHistoryDetail[];

  constructor(data: any) {
    this.retailerPurchaseOrderNumber = data.retailerPurchaseOrderNumber;
    this.createdDate = data.createdDate;
    this.summaryAmount = data.summaryAmount;
    this.retailerPurchaseOrderHistoryDetails =
      data.retailerPurchaseOrderHistoryDetails?.map(
        (x: RetailerPurchaseOrderHistoryDetail) =>
          new RetailerPurchaseOrderHistoryDetail(x)
      );
  }
}

export class RetailerPurchaseOrderHistoryDetail {
  shipFromName: string;
  purchaseOrderNumber: string;
  status: string;
  netAmount: number;

  constructor(data: any) {
    this.shipFromName = data.shipFromName;
    this.purchaseOrderNumber = data.purchaseOrderNumber;
    this.status = data.status;
    this.netAmount = data.netAmount;
  }
}
