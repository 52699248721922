import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IBrand, IBrandRecommendDataTable } from '@core/models/retailer/brand.model';
import { environment } from '@environments/environment';
import { BrandParams } from '@modules/main/pages/retailer/components/all-recommend-brands/all-recommend-brands.component';
import { ClearParams } from '@modules/shared/clear-params';
import { Observable } from 'rxjs';

export interface Pagination {
  page: number;
  limit: number;
}

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  basePath = `${environment.apiProductUrl}`;
  constructor(private http: HttpClient) { }

  getBrand(): Observable<IBrand[]> {
    return this.http.get<IBrand[]>(
      `${this.basePath}/brands/all`
    );
  }

  getRecommendBrand(params: BrandParams): Observable<IBrandRecommendDataTable> {
    return this.http.get<IBrandRecommendDataTable>(
      `${this.basePath}/brands/recommend`, {
      params: ClearParams(params),
    }
    )
  }
}
