<ngx-spinner
    bdColor="rgb(0,0,0,0.07)"
    size="medium"
    color="#21C063"
    type="ball-clip-rotate"
    [fullScreen]="false"
    name="spinner"
    style="margin-top: 0px !important"
></ngx-spinner>
<div class="aura-container-custom" *ngIf="!loading">
    <div class="mx-lg-5">
        <nav id="header-page" class="navbar bg-body-tertiary mb-md-4">
            <div class="px-0">
                <div
                    class="back-button justify-content-start pointer-cursor"
                    (click)="goBack()"
                >
                    <i
                        class="fa-solid fa-arrow-left"
                        style="width: 2.25rem"
                        aria-hidden="true"
                    ></i>
                    <span>{{ 'ACTION.BACK_PAGE' | translate }}</span>
                </div>
            </div>
            <div *ngIf="!checkProduct && !data" class="no-product">
                <div class="pt-3">
                    <img src="assets/images/no_product.svg" alt="" />
                    <h4>{{ 'TITLE.PRODUCT_EMPTY' | translate }}</h4>
                    <p>{{ 'TITLE.PRODUCT_NOT_AREA' | translate }}</p>
                </div>
            </div>
        </nav>

        <div *ngIf="data && checkProduct">
            <div class="row">
                <div class="col-12 col-md-6 d-flex justify-content-center">
                    <div class="carousel-area w-100 h-100">
                        <ngb-carousel
                            [showNavigationArrows]="checkProductImage()"
                            [showNavigationIndicators]="checkProductImage()"
                        >
                            <ng-template
                                ngbSlide
                                *ngFor="
                                    let item of data.product.productImages;
                                    let i = index
                                "
                            >
                                <div class="picsum-img-wrapper banner-area">
                                    <img
                                        class="banner-custom w-100 h-100"
                                        [src]="item.filePath"
                                        (error)="handleImageError($event)"
                                        alt="slide banner"
                                    />
                                    <div
                                        *ngIf="
                                            promotionDetail?.type ===
                                                'Discount' && promotionDetail
                                        "
                                        class="discount-label"
                                    >
                                        -{{
                                            getDiscountPrice(
                                                promotionDetail.promotionDiscounts
                                            )
                                        }}
                                    </div>
                                    <div
                                        *ngIf="
                                            promotionDetail?.type === 'Gift' &&
                                            promotionDetail
                                        "
                                        class="gift-label"
                                    >
                                        <img
                                            [src]="'assets/icons/giftbox.svg'"
                                        />
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-carousel>
                    </div>
                </div>
                <div
                    class="col-12 col-md-6 align-self-center align-content-end order-sm-2 mt-3"
                >
                    <p
                        class="fw-bolder fs-hd"
                        [attr.data-cy]="data.id + '-name'"
                    >
                        {{
                            currentLang === 'th'
                                ? data.product.name
                                : data.product.nameEN
                        }}
                    </p>
                    <p class="mb-0" [attr.data-cy]="data.id + '-ean-number'">
                        EAN : {{ data.product.ean }}
                    </p>
                    <p class="mb-0" [attr.data-cy]="data.id + '-brand'">
                        {{ 'MENU.BRAND' | translate }} :
                        {{
                            currentLang === 'th'
                                ? data.product.brandName
                                : data.product.brandNameEn
                        }}
                    </p>
                    <div class="d-flex align-items-center gap-2 mt-2 mb-3">
                        <img
                            class="poster"
                            [src]="storeImage"
                            onerror="this.src='assets/images/image_error.jpg'"
                        />
                        <p class="fs-sm mb-0">{{ data.stockist.name }}</p>
                    </div>

                    <div class="">
                        <p
                            class="mb-0 fs-sm text-color-grey"
                            [attr.data-cy]="data.id + '-label-unit-price'"
                        >
                            {{ 'PAYMENT.UNIT_PRICE' | translate }}
                        </p>
                        <p
                            class="fw-bolder"
                            [attr.data-cy]="data.id + '-unit-price'"
                        >
                            {{ data.price | currency: 'THB' : 'symbol-narrow' }}
                        </p>
                    </div>
                    <!-- <div>
                        <p [attr.data-cy]="product.id + '-store-detail'">จัดส่งจาก {{product.stockistAddress}}</p>
                    </div> -->
                    <div class="section-detail">
                        <div class="group-detail under-border">
                            <div class="fw-normal">
                                {{ 'TITLE.DETAILE' | translate }}
                            </div>
                            <p
                                class="remark"
                                [attr.data-cy]="data.id + '-remark'"
                            >
                                {{
                                    currentLang === 'th'
                                        ? data.product.discription || '-'
                                        : data.product.discriptionEN || '-'
                                }}
                            </p>
                        </div>

                        <div class="section-promotion" *ngIf="promotionDetail">
                            <div class="promotion-header d-flex">
                                <img
                                    [src]="
                                        promotionDetail.type === 'Discount'
                                            ? 'assets/icons/promo_tag.svg'
                                            : 'assets/images/promotion_icon.png'
                                    "
                                    width="20px"
                                    height="20px"
                                    alt=""
                                />
                                <div class="title">
                                    {{ 'TITLE.PROMOTION' | translate }}
                                </div>
                            </div>
                            <div>{{ promotionDetail.description }}</div>
                            <br />
                            <div>
                                <div
                                    *ngIf="
                                        promotionDetail.type === 'Gift' &&
                                        promotionDetail.giftAways &&
                                        promotionDetail.promotionProducts
                                    "
                                >
                                    {{ 'TITLE.GIFT_LIST' | translate }}
                                    <ul
                                        *ngFor="
                                            let item of promotionDetail.giftAways
                                        "
                                    >
                                        <li>
                                            {{
                                                currentLang === 'th'
                                                    ? item.name
                                                    : item.nameEn
                                            }}
                                            {{ 'TITLE.QUANTITY' | translate }}
                                            {{ item.quantity }}
                                            {{
                                                'TITLE.UNIT_AMOUNT' | translate
                                            }}
                                        </li>
                                    </ul>
                                    <table class="promotion-table">
                                        <tr>
                                            <td class="w-50" id="header">
                                                {{ 'TITLE.AMOUNT' | translate }}
                                            </td>
                                            <td class="w-50" id="header">
                                                {{
                                                    'PAYMENT.PROMOTION_DISCOUNT'
                                                        | translate
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {{
                                                    promotionDetail
                                                        .promotionProducts[0]
                                                        .every
                                                }}
                                            </td>
                                            <td>
                                                {{ 'TITLE.GIFT_1' | translate }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                                <table
                                    class="promotion-table"
                                    *ngIf="promotionDetail.type === 'Discount'"
                                >
                                    <tr class="table-header">
                                        <td class="w-50" id="header">
                                            {{ 'TITLE.AMOUNT' | translate }}
                                        </td>
                                        <td class="w-50" id="header">
                                            {{
                                                'PAYMENT.PROMOTION_DISCOUNT'
                                                    | translate
                                            }}
                                        </td>
                                    </tr>
                                    <tr
                                        *ngFor="
                                            let item of promotionDetail.promotionDiscounts
                                        "
                                    >
                                        <td *ngIf="item.every === null">
                                            {{ item.min }} -
                                            {{
                                                item.max
                                                    ? item.max
                                                    : ('TITLE.NOLIMIT'
                                                      | translate)
                                            }}
                                        </td>
                                        <td
                                            *ngIf="
                                                item.max === null &&
                                                item.min === null
                                            "
                                        >
                                            {{
                                                'TITLE.EVERY_AMOUNT' | translate
                                            }}
                                            {{ item.every }}
                                        </td>
                                        <td>
                                            {{ item.discount }}
                                            {{
                                                item.type === 'Percent'
                                                    ? '%'
                                                    : '฿'
                                            }}
                                        </td>
                                    </tr>
                                </table>

                                <div class="mb-4">
                                    {{ 'TITLE.PROMOTION_PERIOD' | translate }}
                                    {{
                                        formatDateThai(
                                            promotionDetail.startDate
                                        )
                                    }}
                                    -
                                    {{
                                        formatDateThai(promotionDetail.endDate)
                                    }}
                                </div>
                                <div class="promotion-header">
                                    {{
                                        'TITLE.PARTICIPATING_PRODUCTS'
                                            | translate
                                    }}
                                </div>
                                <div
                                    class="aura-sub-container-custom section-promotion-product content-area"
                                >
                                    <div class="shelve d-flex gap-3">
                                        <div
                                            class="card-box-position"
                                            *ngFor="
                                                let item of promotionDetail.promotionProducts
                                                    | slice: 0 : 10;
                                                let i = index
                                            "
                                            [attr.data-cy-row-number]="i + 1"
                                        >
                                            <div
                                                class="promotion-product-item"
                                                (click)="
                                                    handleClickProduct(
                                                        item.stockistProductId
                                                    )
                                                "
                                            >
                                                <img
                                                    class="product-item"
                                                    [src]="
                                                        item.coverImagePathThump
                                                    "
                                                    (error)="
                                                        handleImageError($event)
                                                    "
                                                    alt="slide banner"
                                                />
                                                <div class="name">
                                                    {{
                                                        currentLang === 'th'
                                                            ? item.productName
                                                            : item.productNameEn
                                                    }}
                                                </div>
                                                <div class="unit">
                                                    {{
                                                        'PAYMENT.UNIT_PRICE'
                                                            | translate
                                                    }}
                                                </div>
                                                <div class="price">
                                                    {{
                                                        item.price
                                                            | currency
                                                                : 'THB'
                                                                : 'symbol-narrow'
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="footer-area">
                        <div class="quantity-item">
                            <p class="align-self-center mb-0">
                                {{ 'TITLE.QUANTITY' | translate }}
                            </p>
                            <div class="d-flex gap-2 align-items-baseline">
                                <button
                                    class="btn"
                                    (click)="decreaseQuantity()"
                                    [disabled]="quantity <= 1"
                                >
                                    <img src="assets/icons/MinusIcon.svg" />
                                </button>
                                <input
                                    #currentQuantity
                                    class="amount"
                                    data-cy="item-quantity"
                                    type="text"
                                    value="{{ quantity }}"
                                    [min]="1"
                                    oninput="this.value = this.value.replace(/^0+|[^0-9]|\b0+/g);"
                                />
                                <button
                                    class="btn"
                                    (click)="increaseQuantity()"
                                >
                                    <img src="assets/icons/PlusIcon.svg" />
                                </button>
                            </div>
                        </div>
                        <button
                            [attr.data-cy]="data.id + '-show-product-info'"
                            *ngIf="data.id"
                            class="btn btn-add-product fs-lg"
                            (click)="updateCartItem()"
                            [disabled]="quantity <= 0 || !quantity"
                        >
                            {{ 'ACTION.ADD_CART' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
