import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Interceptor } from '@core/interceptors/interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '@environments/environment';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { LayoutRetailerComponent } from './layouts/layout-retailer/layout-retailer.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { RetailerModule } from '@modules/main/pages/retailer/retailer.module';
import { LandingPageComponent } from './modules/main/pages/landing-page/landing-page.component';
import { SharedModule } from '@modules/shared/shared.module';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import localeTh from '@angular/common/locales/th';
import { TranslationService } from '../app/modules/i18n/translation.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { ServiceWorkerModule } from '@angular/service-worker';

registerLocaleData(localeTh, 'th');
const initialConfig = () => {
    const el = document.getElementById('web-config');
    if (el) {
        const config = el.innerText.trim();
        if (config) {
            environment.CONFIG = JSON.parse(config);
            for (const c in environment.CONFIG) {
                environment[c] = environment.CONFIG[c];
            }
        }
        el.remove();
    }
};


@NgModule({
    declarations: [AppComponent, LayoutRetailerComponent, LandingPageComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgbModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireMessagingModule,
        RetailerModule,
        SharedModule,
        InfiniteScrollModule,
        NgxSpinnerModule,
        TranslateModule.forRoot()
        // ServiceWorkerModule.register('ngsw-worker.js', {
        //   enabled: true,
        //   // Register the ServiceWorker as soon as the application is stable
        //   // or after 30 seconds (whichever comes first).
        //   registrationStrategy: 'registerWhenStable:30000'
        // }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Interceptor,
            multi: true,
        },
        {
            provide: APP_BASE_HREF,
            useFactory: initialConfig,
        },
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService,
        TranslationService,
        TranslateService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.addLangs(['en', 'th']);
    translate.setDefaultLang('en');
    const local =  localStorage.getItem('local');
    if (local) {
      const browserLang = local;
      translate.use(browserLang.match(/en|th/) ? browserLang : 'en');
    } else {
      translate.use('th')
    }
  }
}
