import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { APP_NAME } from '@helpers/app.constants';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
    constructor(private router: Router) {}
    appName = APP_NAME;

    ngOnInit(): void {
        const verify = localStorage.getItem('isVerify');
        if (!verify) {
            localStorage.setItem('isVerify', 'wait');
        }
    }

    navigateToNewPage() {
        this.router.navigate(['/auth/retailer-login']);
    }

    onStockistsLogin() {
        window.location.href =
            'https://tnt-stockist-dev.odd.works/auth/stockist-login';
    }
}
