import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductParams, ProductStockist } from '@core/models/retailer/product.model';
import { AzureBlobService } from '@core/services/retailer/azure-blob.service';
import { FavoriteService } from '@core/services/retailer/favorite.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-card-item-product',
    templateUrl: './card-item-product.component.html',
    styleUrls: ['./card-item-product.component.scss'],
})
export class CardItemProductComponent implements OnInit {
    @Input() item: ProductStockist | undefined;
    @Input() filter: ProductParams | undefined

    productImage = '';
    storeImage = '';
    currentLang = 'th';
    isLoadingFavorite = false

    constructor(
        private router: Router,
        private azureBlobService: AzureBlobService,
        private translate: TranslateService,
        private favoriteService: FavoriteService
    ) { }

    onClickCard() {
        if (this.filter) {
            const encodeFilter = JSON.stringify(this.filter)
            localStorage.setItem('oldFilter', encodeFilter)
        }
        this.router.navigateByUrl(`/retailer/product-detail/${this.item?.id}`);
    }

    async ngOnInit(): Promise<void> {
        this.currentLang = this.translate.currentLang;
        if (this.item) {
            await this.ImageurlToFile(
                this.item.product.coverImagePathThump,
                'Product'
            );
            const stkID = this.item.stockist.id;
            const image = this.item.stockist.logoPathThump.split("/").pop();
            this.storeImage = `${environment.apiLoginUrl}/stockists/logo/${stkID}/${image}`
        }
    }

    async ImageurlToFile(filePath: string, type: 'Product' | 'Store') {
        if (!filePath) return;
        if (filePath.includes('http')) {
            if (type === 'Product') {
                this.productImage = filePath;
            } else {
                this.storeImage = filePath;
            }
            return;
        }
        this.azureBlobService
            .getLogoImageStockists(filePath)
            .subscribe((res: File) => {
                const customFile: File = res as File;
                this.displayImage(customFile, type);
            });
    }

    displayImage(file: File, type: 'Product' | 'Store') {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result) {
                const arrayBuffer = reader.result as ArrayBuffer;
                const valueToAdd = arrayBuffer || '';
                if (type === 'Product') {
                    this.productImage = valueToAdd.toString();
                } else {
                    this.storeImage = valueToAdd.toString();
                }
            }
        };
        reader.readAsDataURL(file);
    }

    calculateDiscountPrice(item: ProductStockist) {
        if (item.promotions && item.promotions.length > 0) {
            const promotion = item.promotions[0];

            if (promotion?.type === 'Discount') {
                const { discountType, discount } = promotion;
                return discountType === 'Percent'
                    ? item.price * (1 - discount / 100)
                    : item.price - discount;
            }
        }
        return item.price;
    }

    onClickFavorite(stockistProductId: number, type: 'Add' | 'Remove') {
        if (this.isLoadingFavorite) return
        switch (type) {
            case 'Add':
                this.isLoadingFavorite = true
                this.favoriteService.AddFavorite(stockistProductId).subscribe({
                    next: () => {
                        if (this.item) {
                            this.item.favorited = true
                        }
                        this.isLoadingFavorite = false
                    },
                    error: (err) => {
                        console.error(err)
                        this.isLoadingFavorite = false
                    }
                })
                break;
            case 'Remove':
                this.isLoadingFavorite = true
                this.favoriteService.RemoveFavorite(stockistProductId).subscribe({
                    next: () => {
                        if (this.item) {
                            this.item.favorited = false
                        }
                        this.isLoadingFavorite = false
                    },
                    error: (err) => {
                        console.error(err)
                        this.isLoadingFavorite = false
                    }
                })
                break;
            default:
                break;
        }
    }
}
