<div>
  <div class="mt-3">
    <div class="col d-flex align-items-center checkbox border-bottom pb-2">
      <input [(ngModel)]="checkedStockist" (ngModelChange)="onSelectStockists()" id="store-{{stockistId}}-checkbox" (check)="checkedStockist" type="checkbox">
      <label for="store-{{stockistId}}-checkbox" class="me-2"></label>
      <img class="px-2" src="assets\icons\Store_Icon.svg" alt="notFound" />
      <label for="store-{{stockistId}}-checkbox" class="fs-6 fw-semibold mt-1">{{stockistName}}</label>
    </div>
    <div class="col">
      <app-my-cart-product *ngFor="let product of productList" [product]="product"(productChanged)="onChangeProduct($event)" (productSelected)="onSelectProduct($event)" [isManageMode]="isManageMode"></app-my-cart-product>
    </div>
    
  </div>
</div>
<div class="bottom-border remove-padding"></div>
