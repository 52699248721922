<div>
    <div class="search-box">
        <img src="assets/icons/Chevron_left.svg" (click)="navigateBack()">
        <div class="w-100">
            <app-input-search placeholder="{{ 'PLACEHOLDER.SEARCH' | translate }}" (searchChange)="search($event)"
                [oldKeyword]="params.keywords"></app-input-search>
        </div>
    </div>


    <div infiniteScroll [infiniteScrollDistance]="20" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
        style="padding-bottom: 4rem;">
        <div class="d-flex px-3 px-md-4 justify-content-between align-items-center align-self-stretch text-search-result"
            [class.filterSelected]="productList.length === 0">
            <span *ngIf="productList.length !== 0">{{total}} {{ 'TITLE.UNIT' | translate}}</span>
            <button class="fillter-btn" *ngIf="filterTotal ||productList.length" [class.selected]="filterTotal"
                (click)="openFilterModal()">
                <img [src]="filterTotal ? 'assets/icons/fillter-white_icon.svg' : 'assets/icons/fillter_icon.svg'">
                {{ 'TITLE.FILTER' | translate}}
                <div class="d-flex align-content-center">
                    <span class="number-circle" *ngIf="filterTotal">
                        {{ filterTotal }}
                    </span>
                </div>
            </button>
        </div>
        <div>
            <div class="productlist container-fluid d-flex justify-content-start row row-cols-2 row-cols-md-4">
                <div class="card-loop" *ngFor="let item of productList; let i = index" [attr.data-cy-row-number]="i+1">
                    <app-card-item-product [item]="item" class="d-flex justify-content-center" [filter]="params" />
                </div>
            </div>
        </div>
        <div id="loading-all-product" *ngIf="isLoading" class="overlay">
            <ngx-spinner bdColor="rgb(0,0,0,0)" size="small" color="#21C063" type="ball-clip-rotate"
                [fullScreen]="false" name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
        </div>
    </div>
    <div class="position-absolute start-50 translate-middle" style="top: 57%;" *ngIf="showNotFoundMessage()">
        <img src="assets/icons/Not_Found_Item_Icon.svg">
        <div class="text-center">{{ 'TITLE.PRODUCT_EMPTY' | translate}}</div>
        <div class="text-center">{{ 'TITLE.TRY_SEARCH' | translate}}</div>
    </div>
    <div *ngIf="isOpenFilterModal">
        <app-filter-product-modal [(isOpenModal)]="isOpenFilterModal" [selectedBrand]="params.brandIds || []"
            [selectedCategory]="params.categoryIds || []" [selectedStockist]="params.stockistIds || []"
            [orderBy]="params.orderBy || null" [limit]="params.limit" (filterEvent)="filterProduct($event)"
            (closeFIlterEvent)="onCloseFilterProduct($event)"></app-filter-product-modal>
    </div>
</div>