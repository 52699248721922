<nav class="navbar bg-body-tertiary">
    <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarTogglerMenu"
            aria-controls="navbarTogglerMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <img src="assets/images/WABI2b_Horizontal.svg" alt="404" routerLink="/retailer" height="48px">
        <div class="d-flex gap-3 me-2">
            <i class="fa-regular cart-notify" (click)="goToNotification()" aria-hidden="true">
                <div class="badge bell" *ngIf="notificationCount > 0"><!--loading notifications badge -->
                    <img class="badge-notify-dot" src="assets/icons/Alert.svg" width="12px" height="12px" alt="" />
                </div>
                <div class="bell" *ngIf="notificationCount === 0"><!--loading notifications badge -->
                </div>
            </i>
            <i class="fa-regular cart-shopping" (click)="goToCart()" aria-hidden="true">
                <div class="badge badge-notify" *ngIf="cartItemsCount > 0 && cartItemsCount < 100">{{
                    getDisplayedCartItemsCount() }}</div>
                <div class="badge badge-notify-max" *ngIf="cartItemsCount > 99">{{ getDisplayedCartItemsCount() }}+
                </div>
            </i>
        </div>

    </div>
</nav>
<div class="offcanvas offcanvas-start" tabindex="-1" id="navbarTogglerMenu" aria-labelledby="navbarTogglerMenuLabel">
    <div class="offcanvas-header">
        <i class="fa-solid fa-angle-left" data-bs-dismiss="offcanvas" style="width: 2.25rem"></i>
    </div>
    <div class="offcanvas-body fs-5">
        <div class="row">
            <div class="row my-3" routerLink="/retailer/profile" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#navbarTogglerMenu">
                <div class="col-8">{{ "SIDE_BAR.PROFILE" | translate }}</div>
                <div class="col-1 offset-3">
                    <i class="fa-regular fa-arrow-right"></i>
                </div>
            </div>
            <div class="row my-3" routerLink="/retailer/order-history" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#navbarTogglerMenu">
                <div class="col-8">{{ "SIDE_BAR.HISTORY" | translate }}</div>
                <div class="col-1 offset-3">
                    <i class="fa-regular fa-arrow-right"></i>
                </div>
            </div>
            <!-- <div class="row my-3">
                <div class="col-8">{{ "SIDE_BAR.FAVORIST" | translate }}</div>
                <div class="col-1 offset-3">
                    <i class="fa-regular fa-arrow-right"></i>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-8">{{ "SIDE_BAR.QUESTION" | translate }}</div>
                <div class="col-1 offset-3">
                    <i class="fa-regular fa-arrow-right"></i>
                </div>
            </div> -->
            <div class="row my-3" routerLink="/retailer/language" type="button" data-bs-dismiss="offcanvas">
                <div class="col-8">{{ "SIDE_BAR.LANGUAGE" | translate }}</div>
                <div class="col-1 offset-3">
                    <i class="fa-regular fa-arrow-right"></i>
                </div>
            </div>
        </div>
        <p class="mt-5">{{ "SIDE_BAR.CONTACT" | translate }}
            <span class="flex" style="font-weight: 300;">{{ "SIDE_BAR.BUSINESS_HOURE" | translate }}</span>
        </p>
        <div class="row">
            <div class="row align-item-center my-2" type="button" data-bs-toggle="offcanvas"
                data-bs-target="#navbarTogglerMenu" (click)="openChat()">
                <img class="col-2" src="assets/icons/chat-icon.svg" alt="line" />
                <div class="col-8">{{ "SIDE_BAR.CHAT" | translate }}</div>
                <i class="col-1 offset-1 fa-regular fa-arrow-right"></i>
            </div>
            <a href="tel:02-616-5555">
                <div class="row align-item-center my-2">
                    <div class="col-2 d-flex justify-content-center align-items-center">
                        <i><img class="size" src="assets/images/coke-icon.png" alt=""></i>
                    </div>
                    <div class="col-10">
                        <div class="flex">{{ "SIDE_BAR.CALL" | translate }}</div>
                    </div>
                </div>
            </a>
        </div>
        <div class="d-flex flex-column my-4">
            <button type="button" class="btn btn-outline-danger" (click)="logout()" data-cy="logout-btn"
                data-bs-toggle="offcanvas">
                {{ "SIDE_BAR.LOGOUT" | translate }}
            </button>
        </div>
    </div>
</div>