<div class="aura-container-custom">
    <div class="grey-background"></div>
    <nav id="header-page" class="navbar bg-body-tertiary mb-md-4">
        <div class="px-3">
            <div class="back-button justify-content-start pointer-cursor " (click)="goToProductsPage()">
                <i class="fa-solid fa-arrow-left" style="width: 2.25rem" aria-hidden="true"></i>
                <span>{{ "ACTION.BACK_PAGE" | translate }}</span>
            </div>
        </div>
    </nav>
    <div class="overlay" *ngIf="isLoading">
        <ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false" name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
    </div>
    <div *ngIf="orderHistoryList.length > 0 && isLoading">
        <div class="emptyBg d-flex justify-content-center body-pane">
            <img src="assets/images/empty-box.svg" alt="assets/images/image_error.jpg" />
            <span class="head fs-hd pt-3">{{ "TITLE.NO_ORDER" | translate }}</span>
        </div>
    </div>
    <div *ngIf="orderHistoryList.length>0">
        <div *ngFor="let order of orderHistoryList" style="background-color: #FFFFFF;">
            <div class="d-flex justify-content-between align-items-center mb-2" aria-hidden="true"
                style="background-color: #DFFBEA; padding: 0.5rem 1rem">
                <div class="d-flex align-items-center gap-2">
                    <img width="48px" height="48px" src="assets\icons\paper-check.svg" />
                    <div>
                        <div class="d-flex gap-1 align-items-baseline">
                            <div class="fs-md fw-semibold">{{ order.orderGroup.id }}</div>
                        </div>
                        <p class="fs-sm mb-0">{{ order.orderGroup.createdAt | displayDate }}</p>
                    </div>


                </div>
            </div>
            <div *ngFor="let orderDetail of order.orderGroup.orders; let i = index">
                <div class="d-flex flex-column mb-2" aria-hidden="true"
                    style="padding: 0.5rem 1rem;border-bottom: 1px solid #EFEFEF" (click)="
                        onSelectPurchaseOrder(
                            orderDetail.id
                        )
                    ">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex gap-1 align-items-center">
                            <!-- <div class="checklist"></div> -->
                            <img src="assets\icons\Store_Icon.svg" alt="notFound" />
                            <p class="fs-md fw-md mb-0">
                                {{ orderDetail.stockistName }}
                            </p>
                        </div>
                        <p class="fs-md fw-sb mb-0 font-amount-grey">
                            {{
                            orderDetail.netAmount
                            | currency : 'THB' : 'symbol-narrow'
                            }}
                        </p>
                    </div>
                    <div class="d-flex justify-content-between my-2">
                        <div>
                            <p class="fs-xs fw-rg mb-0 font-second-grey">
                              {{ "TITLE.NUMBER" | translate }}
                            </p>
                            <p class="fs-md fw-sb mb-0">
                                {{ orderDetail.id }}
                            </p>
                        </div>

                        <div *ngIf="orderDetail.status !== 'Cancelled'" align="center" class="status-label mb-0 fs-sm fw-md" style="width: 6rem;height: auto;border-radius: 14px;"
                            [ngClass]="orderDetail.status.toLowerCase()">
                            {{ getStatusLabel(orderDetail.status) }}
                        </div>
                        <div *ngIf="orderDetail.status === 'Cancelled'" class="fw-semibold order-status d-flex flex-column align-items-center" [ngClass]="orderDetail.status.toLocaleLowerCase()">
                          {{ "TITLE.CANCEL" | translate }}
                          <div class="order-sub-title d-flex justify-content-center align-items-center">
                            <label *ngIf="orderDetail.actionBy === 'Retailer'">{{ "TITLE.CANCEL_RTL" | translate }}</label>
                            <label *ngIf="orderDetail.actionBy === 'Stockist'">{{ "TITLE.CANCEL_STK" | translate }}</label>
                          </div>
                        </div>
                    </div>
                    <p class="mb-0 fs-sm">{{ "TITLE.ORDER_AT" | translate }} {{ orderDetail.createdAt | displayDate }}</p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="orderHistoryList.length===0" class="empty-order">
        <div class="content">
            <img src="../../../../../../../assets/images/empty-history.png" alt="Empty history image">
            <p>{{ "TITLE.EMPTY_ORDER" | translate}}</p>
        </div>
    </div>

</div>
