export enum ModalState {
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Info = 'info',
  Confirm = 'confirm',
}

export enum PurchaseOrderStatus {
  Pending = 'Pending',
  Confirmed = 'ConfirmOrder',
  Cancelled = 'Cancelled',
}

export enum ButtonTag {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
  Warning = 'warning',
  Info = 'info',
  Light = 'light',
  Dark = 'dark'
}

export enum NotificationTab {
  Order = "Order",
  News = "News",
  Coupon = "Coupon",
  Promotion = "Promotion",
}
