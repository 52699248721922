import { Component, OnInit } from '@angular/core'
import { RetailerOrderStatus } from '@core/models/retailer/retailer-enum';
import { TranslateService } from '@ngx-translate/core';

interface CancelledInfo {
  purchaseOrderNumber: string;
  status: string;
}
@Component({
  selector: 'app-canceled',
  templateUrl: './canceled.component.html',
  styleUrls: ['./canceled.component.scss']
})
export class CanceledComponent implements OnInit {
  constructor(
    private translate: TranslateService,

  ) { }
  cancelledInfo: CancelledInfo = {
    purchaseOrderNumber: '',
    status: ''
  }
  ngOnInit(): void {
    const urlParams = new URLSearchParams(window.location.search);

    const purchaseOrderNumber = urlParams.get('purchaseOrderNumber');
    const status = urlParams.get('status');

    if (purchaseOrderNumber !== null && status !== null) {
      this.cancelledInfo = {
        purchaseOrderNumber: purchaseOrderNumber,
        status: status,
      };
    } else {
      console.error("One or more parameters are missing or null.");
    }
  }

  getCancelledLabel(status:string): string {
    switch (status.toUpperCase()) {
      case RetailerOrderStatus.PENDING:
        return `${this.translate.instant('TITLE.ORDER')} ${this.cancelledInfo.purchaseOrderNumber} | ${this.translate.instant('TITLE.BEEN_CANCEL')}`;
      case RetailerOrderStatus.CONFIRMED:
        return `${this.translate.instant('TITLE.MODAL_CANCEL')}`;
      case RetailerOrderStatus.CANCELLED:
        return this.translate.instant('TITLE.CANCEL');
      default:
        return 'Unknown status';
    }
  }
}
