<ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false"
    name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
<div class="bg-grey">
    <div class="aura-container-custom">
        <div class="px-0">
            <div class="back-button justify-content-start pointer-cursor " (click)="goBack()">
                <i class="fa-solid fa-arrow-left" style="width: 2.25rem" aria-hidden="true"></i>
                <span>{{ "ACTION.BACK_PAGE" | translate }}</span>
            </div>
        </div>
    </div>

    <div class="d-flex box">
        <div class="flex"><img src="assets/icons/profile-icon/address.svg" /></div>
        <div class="flex">
            <div class="d-flex text">{{ 'PROFILE.ADDRESS' | translate }}</div>
            <div class="d-flex title">{{retailerProfile?.name}}</div>
            <div class="d-flex text">{{ getAddress() }}</div>
        </div>
    </div>

    <div class="d-flex box">
        <div class="flex"><img src="assets/icons/profile-icon/wallet.svg" /></div>
        <div class="flex">
            <div class="d-flex text">{{ 'PROFILE.BILLING_ADDRESS' | translate }}</div>
            <!-- <div class="d-flex title">566X+6C5</div> -->
            <div class="d-flex text">{{ getBillingAddress() }}</div>
        </div>
    </div>

    <div class="d-flex box">
        <div class="flex"><img src="assets/icons/profile-icon/profile.svg" /></div>
        <div class="flex">
            <div class="d-flex text">{{ 'PROFILE.DATE_TIME' | translate }}</div>
            <div class="d-flex title">{{ formatTime(retailerProfile?.officeHoursStart, retailerProfile?.officeHoursEnd) }}</div>
            <div class="d-flex open-day">
                <div *ngFor="let day of days">
                <span style="margin-right: 10px;" [ngStyle]="{
                        color: isDayActive(
                            day,
                            retailerProfile?.officeDay
                        )
                            ? colors[1]
                            : 'black'
                    }">{{ day }}</span>
            </div>
            </div>
        </div>
    </div>

    <div class="d-flex justify-content-between align-items-center box" (click)="!linkedLine ? onClickLinkLine() : onClickUnlinkLine()">
        <div class="d-flex align-items-center">
            <img style="height: 24px; padding-right: 8px;" src="assets/images/LINE_Brand_icon.png" alt="line" />
            <div class="ml-2"> {{ linkedLine ? ('PROFILE.ACCOUNT_LINKED' | translate) : ('PROFILE.BIND_AN_ACCOUNT' | translate) }}</div>
        </div>
        <i class="fa-solid fa-arrow-right" *ngIf="!linkedLine" aria-hidden="true"></i>
        <p class="unlinkLine m-0" *ngIf="linkedLine">{{ 'PROFILE.CANCEL_CONNECT' | translate }}</p>
    </div>
</div>
