<div>
    <div class="search-box">
        <img src="assets/icons/Chevron_left.svg" (click)="navigateBack()">
        <div class="w-100">
            <app-input-search placeholder="{{ 'PLACEHOLDER.SEARCH' | translate }}" (searchChange)="search($event)"></app-input-search>
        </div>
    </div>
    <ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false" name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
    <div infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">

        <div>
            <div class="productlist container-fluid d-flex justify-content-start row row-cols-2 row-cols-md-4">
                <div class="card-loop" *ngFor="let item of productList; let i = index" [attr.data-cy-row-number]="i+1">
                    <app-card-item-product [item]="item" class="d-flex justify-content-center" />
                </div>
            </div>
        </div>
    </div>
    <div class="position-absolute top-50 start-50 translate-middle" *ngIf="showNotFoundMessage()">
        <img src="assets/icons/Not_Found_Item_Icon.svg">
        <div class="text-center">{{ 'TITLE.PRODUCT_EMPTY' | translate}}</div>
        <div class="text-center">{{ 'TITLE.TRY_SEARCH' | translate}}</div>
    </div>
</div>
