import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputPasswordComponent } from './input-password/input-password.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputComponent } from './input/input.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { SelectComponent } from './select/select.component';
import { CardItemProductComponent } from './card-item-product/card-item-product.component';
import { CardStockistComponent } from './card-stockist/card-stockist.component';
import { InputSearchComponent } from './input-search/input-search.component';
import { FormsModule } from '@angular/forms';
import { CardCategoryComponent } from './card-category/card-category.component';
import { CardBrandComponent } from './card-brand/card-brand.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  declarations: [
    InputPasswordComponent,
    InputComponent,
    SelectComponent,
    CardItemProductComponent,
    CardStockistComponent,
    InputSearchComponent,
    CardCategoryComponent,
    CardBrandComponent
  ],
  imports: [
    NgSelectModule,
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(maskConfig),
    FormsModule
  ],
  exports: [
    InputPasswordComponent,
    InputComponent,
    SelectComponent,
    CardItemProductComponent,
    CardStockistComponent,
    InputSearchComponent,
    CardCategoryComponent,
    TranslateModule,
    CardBrandComponent
  ]
})
export class SharedModule { }
