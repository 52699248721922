import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IOrderDetail } from '@core/models/retailer/order-preview.model';
import { CartService } from '@core/services/retailer/cart.service';

@Component({
  selector: 'app-order-history-detail-main',
  templateUrl: './order-history-detail-main.component.html',
  styleUrls: ['./order-history-detail-main.component.scss']
})
export class OrderHistoryDetailMainComponent implements OnInit{
  orderData: IOrderDetail | undefined;

  constructor(private cartService: CartService, private route: ActivatedRoute){}

  ngOnInit(): void {
      this.loadOrderDetail()
  }

  loadOrderDetail (){
    const orderId = this.route.snapshot.params['orderid'];
    if (orderId){
      this.cartService.getOrderById(orderId).subscribe((res) => {
        this.orderData = res
      })
    }

  }
}
