import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IItemProductPayload } from '@core/models/retailer/cart.model';
import { IOrderDetail } from '@core/models/retailer/order-preview.model';
import { ModalMessageAlertComponent } from '../modal-message-alert/modal-message-alert.component';
import { reOrderProducts } from '@core/models/retailer/purchase-order.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseOrderService } from '@core/services/retailer/purchase.service';
import { TranslateService } from '@ngx-translate/core';

interface ErrorResponse {
  error: string | reOrderProducts[]
}

interface ProductReorder {
  productId: number;
  price: number;
  stockistProductId: number;
  quantity: number;
}
@Component({
    selector: 'app-order-history-detail-delivered',
    templateUrl: './order-history-detail-delivered.component.html',
    styleUrls: ['./order-history-detail-delivered.component.scss'],
})
export class OrderHistoryDetailDeliveredComponent {
    @Input() orderDetail: IOrderDetail | undefined
    purchaseOrderNumber = '';
    retailerPurchaseOrderNumber = '';
    currentDate: Date = new Date();
    addressRetailer = '';
    openReorderModal = false
    reOrderProducts: reOrderProducts[] = [];

    constructor(
      private modalService: NgbModal,
      private router: Router,
      private purchaseOrderService: PurchaseOrderService,
      private translate: TranslateService
    ) { }

    private modalRef: NgbModalRef | undefined;


    sumTotalQuatity(): number {
        if (this.orderDetail && this.orderDetail.items) {
            return this.orderDetail.items.reduce(
                (prev, item) => prev + item.quantity,
                0
            );
        } else {
            return 0;
        }
    }

    navigateToOrderHistory() {
      const page = window.localStorage.getItem('page');

      if (page) {
        if (page === 'notification') {
          this.router.navigate(['retailer/my-notification']);
        } else if (page === 'history') {
          this.router.navigate(['retailer/order-history']);
        }
      } else {
        this.router.navigate(['retailer/order-history']);
      }

      window.localStorage.removeItem('page')
    }

    getAddress() {
        return this.orderDetail?.billingAddress + " " + this.orderDetail?.billingSubdistrict + " " + this.orderDetail?.billingDistrict + " " + this.orderDetail?.billingProvince + " " + this.orderDetail?.billingPostCode
    }

    navigateToRequestCancle(){
        this.router.navigate([''])
    }

    getDateLabelWording() {
        // return this.purchaseOrder?.rejectedCancellationDate
        //     ? 'ไม่อนุมัติคำขอยกเลิกเมื่อ'
        //     : 'ยืนยันเมื่อ';
    }

    getDateValue() {
        // return this.purchaseOrder?.rejectedCancellationDate
        //     ? this.purchaseOrder?.rejectedCancellationDate
        //     : this.purchaseOrder?.confirmedDate;
    }

    onClickCloseModal() {
      this.openReorderModal = false
    }

    addItemToCart(): void {
      if (
        !this.orderDetail ||
        this.orderDetail.items.length === 0
      ) {
          return;
      }

      const data = {
        confirm: false
      }

      let product: ProductReorder = {
        productId: 0,
        price: 0,
        stockistProductId: 0,
        quantity: 0
      }

      const products: IItemProductPayload[] =[]

      this.purchaseOrderService.reOrderPurchase(this.orderDetail.id, data).subscribe({
        next: (res) => {

          for (const item of res) {
            product = {
              productId: item.productId,
              stockistProductId: item.stockistProductId,
              price: item.price,
              quantity: item.quantity
            }

            products.push(product)

          }

          localStorage.setItem('productData', JSON.stringify(products));

          this.router.navigate(['/retailer/my-cart'])
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        error: (error: ErrorResponse) => {
          if (typeof(error.error) === 'string') {
            this.modalRef = this.modalService.open(ModalMessageAlertComponent, {
              centered: true,
              backdropClass: 'custom-modal-backdrop'
            });
            this.modalRef.componentInstance.modalConfig = {
              message: this.translate.instant('ORDER_ERROR.ERROR_PRODUCT')
            };
            return
          } else {
            this.reOrderProducts = error.error
            this.openReorderModal = true
          }
        },
    });
    }
    isPurchaseOrderHaveGiftPromotion(order: IOrderDetail): boolean {
      if (order.promotions.length > 0) {
          return order.promotions.some((promotion) => promotion.promotionType === "Gift");
      }
      return false;
  }

  navigateToOldOrder() {
    this.router.navigateByUrl(`retailer/order-history/${this.orderDetail?.refOrderId}`).then(() => window.location.reload())
  }
}
