<ng-container [ngSwitch]="mode">
  <ng-container *ngSwitchCase="LabelMode.DEFAULT" [ngTemplateOutlet]="default"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.INLINE" [ngTemplateOutlet]="inline"></ng-container>
  <ng-container *ngSwitchCase="LabelMode.NO_LABEL" [ngTemplateOutlet]="inputContent"></ng-container>
</ng-container>

<ng-template #default>
  <div class="form-group validated">
    <label class="form-control-label" [for]="name">{{ label | translate }}</label>
    <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
  </div>
</ng-template>

<ng-template #inline>
  <div class="form-group row validated">
    <label class="col-md-4 col-sm-12 input-inline-label form-control-label" [for]="name">{{ label | translate }}</label>
    <div class="col-md-8 col-sm-12">
      <ng-container [ngTemplateOutlet]="inputContent"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #inputContent>
  <ng-container *ngIf="isInputGroup || isInputGroupIcon; then inputGroup; else inputDefault"></ng-container>
</ng-template>

<ng-template #input>
  <input class="form-control" [ngClass]="{ 'is-invalid': isError }" [placeholder]="placeholder | translate"
    [type]="type" [readonly]="readonly" [formControl]="form" [mask]="customMask" [autocomplete]="autocomplete" />
</ng-template>

<ng-template #inputGroup>
  <div class="input-group">
    <ng-container [ngTemplateOutlet]="input"></ng-container>
    <div class="input-group-append">
      <span class="input-group-text">
        <span *ngIf="isInputGroup && type">{{ inputGroupMessage | translate }}</span>
        <i *ngIf="isInputGroupIcon && type==='text'" [ngClass]="inputGroupIcon" class="fa fa-eye fa-lg fa-fw"
          (click)="changeType()"></i>
        <i *ngIf="isInputGroupIcon && type==='password'" [ngClass]="inputGroupIcon" class="fa fa-eye-slash fa-lg fa-fw"
          (click)="changeType()"></i>
      </span>
    </div>
    <div class="invalid-feedback">{{ errorMessage | translate }}</div>
    <div class="invalid-feedback">{{ errorMessageApi | translate }}</div>
  </div>
  <!-- <span class="form-text text-muted">We'll never share your email with anyone else.</span> -->
</ng-template>

<ng-template #inputDefault>
  <ng-container [ngTemplateOutlet]="input"></ng-container>
  <div class="invalid-feedback">{{ errorMessage | translate }}</div>
  <div class="invalid-feedback">{{ errorMessageApi | translate }}</div>
</ng-template>