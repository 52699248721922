import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationList, NotificationParams } from '@core/models/retailer/notification.model';
import { NotificationTab } from '@core/models/shares/enum';
import { NotificationService } from '@core/services/retailer/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-my-notification',
  templateUrl: './my-notification.component.html',
  styleUrls: ['./my-notification.component.scss'],
})
export class MyNotificationComponent implements OnInit {

  currentLang!: string
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private spinner: NgxSpinnerService,
    private translate: TranslateService,
  ) {

  }

  notificationMenu = Object.values(NotificationTab);

  notifications: NotificationList = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  typeTranslations: { [key: string]: any } = {
    Order: { en: 'Order', th: 'คำสั่งซื้อ' },
    News: { en: 'News', th: 'ข่าวสาร' },
    Coupon: { en: 'Coupon', th: 'คูปอง' },
    Promotion: { en: 'Promotion', th: 'โปรโมชัน' }
  };



  totalOrder = 0;
  totalOrderPage = 0
  loading_data = false
  activeType = 'Order';
  havenextPage = true

  params: NotificationParams = {
    type: 'Order',
    limit: 50,
    offset: 0,
    page: 1,
  };

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang || 'en';
    this.notificationService.getMyNotificationCount().subscribe();
    const tab = window.localStorage.getItem('tabNoti');
    if (tab) {
      this.activeType = tab;
      this.params.type = tab
    }
    this.getNotifications();
  }

  setType(type: string) {
    window.localStorage.setItem('tabNoti', type);
    this.params.limit = 10;
    this.params.page = 1;
    const typeEn = this.typeTranslations[type];
    this.params.type = typeEn.en;
    this.activeType = type;
    this.notifications = [];
    this.loading_data = true;
    this.getNotifications();
  }

  getTabTranslation(tab: string, language: string): string {
    return this.typeTranslations[tab][language];
  }

  isActive(type: string): boolean {
    return this.activeType === type;
  }

  getNotifications() {
    this.spinner.show('spinner');

    if (this.notifications.length == 0) {
      this.loading_data = true;
    }

    this.notificationService.getNotifications(this.params).subscribe((res) => {
      this.spinner.hide('spinner');
      this.totalOrder = res.total;
      this.totalOrderPage = res.totalPage;
      this.notifications.push(...res.data)
      this.loading_data = false
    });
  }


  goToProductsPage() {
    window.localStorage.removeItem('tabNoti')
    this.router.navigate(['retailer/products']);
  }

  onSelectNotification(
    notificationId: number,
    orderId?: string
  ) {
    this.notificationService.readNotification(notificationId).subscribe(() => {
      this.notificationService.getMyNotificationCount().subscribe();
      if (this.activeType === 'Order') {
        window.localStorage.setItem('page', 'notification');
        const targetPath = `/retailer/order-history/${orderId}`;
        this.router.navigate([targetPath])
      }
      if (this.activeType !== 'Order') {
        const targetPath = `/retailer/my-notification/${notificationId}`;
        this.router.navigate([targetPath])
      }
    });
  }


  // onScroll() {

  //     if (this.params.page && this.params.page < this.totalOrderPage) {
  //       this.params.page += 1;
  //       this.getNotifications();
  //     }
  // }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (window.innerHeight + 10 + window.scrollY >= document.body.offsetHeight && !this.loading_data && this.params?.page && this.params?.page < this.totalOrderPage) {
      this.params.page += 1;
      this.getNotifications();
    }
  }

}


