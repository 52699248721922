<div class="modal-container  d-flex justify-content-center flex-column">
  <div data-cy="modal-content" class=" text-center pb-2 mt-3">
    <img
    class="mr-5"
    src="assets/icons/alert_fill.svg"
    width="32px"
    height="32px"
    alt="">
  </div>

  <div data-cy="modal-content" class=" text-center ">
    <p class="message-text">{{ modalConfig.message }}</p>
  </div>

</div>
