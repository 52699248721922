import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RetailerRoutingModule } from './retailer-routing.module';
import { ProductDetailComponent } from './components/product-detail/product-detail.component';
import { ProductsComponent } from './components/products/products.component';
import { MyCartComponent } from './components/my-cart/my-cart.component';
import { MyCartProductComponent } from './components/my-cart/product/product.component';
import { MyCartStockistComponent } from './components/my-cart/stockist/stockist.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReviewOrderComponent } from './components/review-order/review-order.component';
import { OrderSuccessComponent } from './components/order-success/order-success.component';
import { OrderHistoryComponent } from './components/order-history/order-history.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { OrderHistoryDetailPendingComponent } from './components/order-history-detail-pending/order-history-detail-pending.component';
import { OrderHistoryDetailCancelrequestedComponent } from './components/order-history-detail-cancelrequested/order-history-detail-cancelrequested.component';
import { OrderHistoryDetailCancelledComponent } from './components/order-history-detail-cancelled/order-history-detail-cancelled.component';
import { OrderHistoryDetailConfirmedComponent } from './components/order-history-detail-confirmed/order-history-detail-confirmed.component';
import { OrderHistoryDetailDeliveredComponent } from './components/order-history-detail-delivered/order-history-detail-delivered.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { DisplayDatePipe } from '@core/pipe/display-date.pipe';
import { ModalMessageComponent } from './components/modal-message/modal-message.component';
import { ShipToAddressComponent } from './components/ship-to-address/ship-to-address.component';
import { ShowProductListComponent } from './components/show-product-list/show-product-list.component';
import { PaymentComponent } from './components/payment/payment.component';
import { OrderHistoryDetailDeprecatedComponent } from './components/order-history-detail-deprecated/order-history-detail-deprecated.component';
import { AllProductComponent } from './components/all-product/all-product.component';
import { SharedModule } from '@modules/shared/shared.module';
import { OrderHistoryDetailMainComponent } from './components/order-history-detail-main/order-history-detail-main.component';
import { OrderProcessedCancelledComponent } from './components/order-processed-cancelled/order-processed-cancelled.component';
import { OrderProcessedCancelrequestedComponent } from './components/order-processed-cancelrequested/order-processed-cancelrequested.component';
import { ReasonCancellationModalComponent } from './components/reason-cancellation-modal/reason-cancellation-modal.component';
import { FilterProductModalComponent } from './components/filter-product-modal/filter-product-modal.component';
import { SearchComponent } from './components/search/search.component';
import { ModalCouponComponent } from './components/modal-coupon/modal-coupon.component';
import { DisplayDateDelivedPipe } from '@core/pipe/display-date-delivedcase';
import { ModalMessageAlertComponent } from './components/modal-message-alert/modal-message-alert.component';
import { ReOrderModalComponent } from './components/reorder-modal/reorder-modal.component';
import { MyNotificationComponent } from './components/my-notification/my-notification.component';
import { MyNotificationDetailComponent } from './components/my-notification-detail/my-notification-detail.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ShowCaseDetailComponent } from './components/show-case-detail/show-case-detail.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BannerPromotionProductComponent } from './components/banner-promotion-product/banner-promotion-product.component';
import { ProfileComponent } from './components/profile/profile.component';
import { TranslateModule } from '@ngx-translate/core';
import { LanguagePageComponent } from './components/language-page/language-page.component';
import { RecommendBrandComponent } from './components/recommend-brand/recommend-brand.component';
import { AllRecommendBrandsComponent } from './components/all-recommend-brands/all-recommend-brands.component';
import { AllFavoriteProductComponent } from './components/all-favorite-product/all-favorite-product.component';

@NgModule({
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        ProductDetailComponent,
        ProductsComponent,
        MyCartComponent,
        MyCartProductComponent,
        MyCartStockistComponent,
        ReviewOrderComponent,
        OrderSuccessComponent,
        OrderHistoryComponent,
        OrderHistoryDetailPendingComponent,
        OrderHistoryDetailCancelrequestedComponent,
        OrderHistoryDetailCancelledComponent,
        OrderHistoryDetailConfirmedComponent,
        OrderHistoryDetailDeliveredComponent,
        SideBarComponent,
        ShipToAddressComponent,
        ShowProductListComponent,
        PaymentComponent,
        ModalMessageComponent,
        DisplayDatePipe,
        OrderHistoryDetailDeprecatedComponent,
        AllProductComponent,
        OrderHistoryDetailMainComponent,
        OrderProcessedCancelledComponent,
        OrderProcessedCancelrequestedComponent,
        ReasonCancellationModalComponent,
        FilterProductModalComponent,
        SearchComponent,
        ModalCouponComponent,
        DisplayDateDelivedPipe,
        ModalMessageAlertComponent,
        ReOrderModalComponent,
        MyNotificationComponent,
        MyNotificationDetailComponent,
        ShowCaseDetailComponent,
        BannerPromotionProductComponent,
        ProfileComponent,
        LanguagePageComponent,
        RecommendBrandComponent,
        AllRecommendBrandsComponent,
        AllFavoriteProductComponent
    ],
    providers: [NgbActiveModal],
    imports: [
        CommonModule,
        FormsModule,
        RetailerRoutingModule,
        NgbModule,
        ReactiveFormsModule,
        GoogleMapsModule,
        SharedModule,
        InfiniteScrollModule,
        NgxSpinnerModule,
        TranslateModule
    ],
    exports: [DisplayDatePipe, SideBarComponent, ShipToAddressComponent, DisplayDateDelivedPipe, TranslateModule],
})
export class RetailerModule { }
