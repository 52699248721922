import { Component, Input, OnInit } from '@angular/core';
import { IItem } from '@core/models/retailer/cart.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-show-product-list',
  templateUrl: './show-product-list.component.html',
  styleUrls: ['./show-product-list.component.scss'],
})
export class ShowProductListComponent implements OnInit {
  @Input() products: IItem[] = [];
  currentLang = 'th'

  constructor(
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang
  }
}
