<div>
    <div class="search-box">
        <img src="assets/icons/Chevron_left.svg" (click)="navigateBack()">
        <div class="w-100">
            <app-input-search placeholder="{{ 'PLACEHOLDER.SEARCH' | translate }}" [oldKeyword]="params.keywords"
                (searchChange)="search($event)"></app-input-search>
        </div>
    </div>
    <div>
        <div>
            <div class="productlist container-fluid d-flex justify-content-start row row-cols-2 row-cols-md-4">
                <div class="card-loop" *ngFor="let item of brandList; let i = index" [attr.data-cy-row-number]="i+1">
                    <app-card-brand [item]="item" class="d-flex justify-content-center"
                        (clickBrand)="navigateToBrandProduct($event)" />
                </div>
            </div>
        </div>
    </div>
    <div class="position-absolute start-50 translate-middle" style="top: 57%;" *ngIf="showNotFoundMessage()">
        <img src="assets/icons/Not_Found_Item_Icon.svg">
        <div class="text-center">{{ "TITLE.PRODUCT_EMPTY" | translate }}</div>
        <div class="text-center">{{ "TITLE.TRY_SEARCH" | translate }}</div>
    </div>
</div>