import { inject } from '@angular/core';
import {
    CanActivateFn,
    Router,
    RouterStateSnapshot,
    ActivatedRouteSnapshot,
} from '@angular/router';
import { AuthUser } from '@core/models/auth/auth-user.model';
import { UserService } from '@core/services/auth/user.service';

export const authGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const authUserService = new UserService();
    const router = inject(Router);

    const user = authUserService.getUser();
    return isUserValid(router, user, state.url);
};

export const isUserValid = (
    router: Router,
    user: AuthUser | null,
    stateUrl: string,
): boolean => {
    if (user) {
        let isRouteCanAccess = false;
        if (user.profile.type === 'RETAILER') {
            isRouteCanAccess = stateUrl.match('retailer') !== null || stateUrl.match('auth/line') !== null;
        }
        if (!isRouteCanAccess) {
            router.navigate(['/auth']);
        }
        return isRouteCanAccess;
    }
    router.navigate(['/auth'], { queryParams: { returnUrl: stateUrl } });
    return false;
};
