<ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false"
    name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
<div class="bg-grey">
    <div class="aura-container-custom">
        <div class="px-0">
            <div class="back-button justify-content-start pointer-cursor " (click)="goBack()">
                <i class="fa-solid fa-arrow-left" style="width: 2.25rem" aria-hidden="true"></i>
                <span>{{ "ACTION.BACK_PAGE" | translate }}</span>
            </div>
        </div>
    </div>

    <div class="d-flex  box pt-3">
        <div class="flex w-100" >
            <div class="d-flex justify-content-between text" (click)="switchLanguage('th')">
                <div>
                  <span>ภาษาไทย</span>
                </div>
                <img
                  *ngIf="currentLang === 'th'"
                  class="img-fluid"
                  src="assets/icons/check.svg"
                  alt="notFound"
                  style="position: absolute; right: 30px;"
                />
            </div>

            <div class="d-flex justify-content-between text" (click)="switchLanguage('en')">
              <div>
                <span>English</span>
              </div>
              <img
                *ngIf="currentLang === 'en'"
                class="img-fluid"
                src="assets/icons/check.svg"
                alt="notFound"
                style="position: absolute; right: 30px;"
              />
            </div>
        </div>
    </div>

</div>
