import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    IProductsResponse,
    ProductParams,
    ProductDetailDataInterface,
    ListProductStockistResponse,
} from '@core/models/retailer/product.model';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { APIResponse } from '@core/models/shares/http.model';
import { Stockist } from '@core/models/retailer/stockist.model';
import { ClearParams } from '@modules/shared/clear-params';
import { APIResponseCategory, CategoryList } from '@core/models/retailer/category.model';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    constructor(private http: HttpClient) { }

    getProducts(): Observable<APIResponse<IProductsResponse>> {
        return this.http.get<APIResponse<IProductsResponse>>(
            `${environment.apiUrl}/product-management/products`
        );
    }

    getProductDetailById(id: number): Observable<ProductDetailDataInterface> {
        return this.http.get<ProductDetailDataInterface>(
            `${environment.apiProductUrl}/stockist-products/${id}/detail`
        );
    }

    getProductsAvailable(
        params: ProductParams
    ): Observable<APIResponse<IProductsResponse>> {
        return this.http.get<APIResponse<IProductsResponse>>(
            `${environment.apiProductUrl}/products/available`,
            {
                params: ClearParams(params),
            }
        );
    }

    getProductsAvailableWithDataTable(
        params: ProductParams
    ): Observable<ListProductStockistResponse> {
        return this.http.get<ListProductStockistResponse>(
            `${environment.apiProductUrl}/products/available`,
            {
                params: ClearParams(params),
            }
        );
    }

    getStockistsAvailable(): Observable<Stockist[]> {
        return this.http.get<Stockist[]>(
            `${environment.apiAdminUrl}/stockists/available`
        );
    }

    getCategoryList(): Observable<APIResponseCategory<CategoryList>> {
        return this.http.get<APIResponseCategory<CategoryList>>(
            `${environment.apiProductUrl}/category/all`
        );
    }
}
