/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { CheckStockist } from '../my-cart.component';
import {
    CartPayload,
    IItemProductPayload,
    IProductMyCart,
    IValidateOrderPayload,
} from '@core/models/retailer/cart.model';

@Component({
    selector: 'app-my-cart-stockist',
    templateUrl: './stockist.component.html',
    styleUrls: ['./stockist.component.scss'],
})
export class MyCartStockistComponent implements OnChanges {
    @Input() isManageMode = false;
    @Input() stockistId = 0;
    @Input() stockistName = '';
    @Input() productList: IProductMyCart[] = [];
    @Input() selectedProduct: IValidateOrderPayload[] = [];
    @Input() checkedAllStockist = false;
    @Output() stockistSelectd: EventEmitter<CheckStockist> =
        new EventEmitter<CheckStockist>();
    @Output() productSelected: EventEmitter<IItemProductPayload> =
        new EventEmitter<IItemProductPayload>();
    @Output() productChanged: EventEmitter<CartPayload> =
        new EventEmitter<CartPayload>();

    checkedStockist = false;

    ngOnChanges(change: SimpleChanges): void {
        if (change['checkedAllStockist'] || change['isManageMode']) {
            if (!this.isManageMode) {
                this.checkedStockist = this.productList.some(
                    (item) => item.isSelected === true
                );
            } else {
                this.checkedStockist = this.productList.every(
                    (item) => item.isSelected === true
                );
            }
        }
    }

    checkDisplay(): boolean {
        return this.productList.some((product) => product.quantity > 0);
    }

    onSelectStockists() {
        this.stockistSelectd.emit({
            stockistsId: this.stockistId,
            checked: !this.checkedStockist,
        });
    }

    onSelectProduct(event: any) {
        this.productSelected.emit(event);
        if (!this.isManageMode) {
            this.checkedStockist = this.productList.some(
                (item) => item.isSelected === true
            );
        } else {
            this.checkedStockist = this.productList.every(
                (item) => item.isSelected === true
            );
        }
    }

    onChangeProduct(event: any) {
        this.productChanged.emit(event);
    }
}
