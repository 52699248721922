/* eslint-disable @typescript-eslint/no-explicit-any */
import { PurchaseOrderActions, PurchaseOrderItem } from '../stockist/preview-order.model';
import { PromotionResponse } from './promotion.model';

export interface PurchaseOrderCreateRequest {
    products: ProductInfo[];
}

export interface ProductInfo {
    code: string;
    quantity: number;
}

export interface reOrderProducts {
  coverImagePathThump: string,
  productId: number,
  productName: string,
  price: number,
  quantity: number,
  totalPrice: number,
  valid?: boolean,
  stockistProductId: number
}

export interface PurchaseOrder {
    id: number;
    stockistName: string;
    stockistAddress: string;
    amount: number;
    purchaseOrderNumber: string;
    createdAt: string;
    shipFromName: string;
    retailerName: string;
    shippingAddress: string;
    totalAmount: number;
    shippingCost: number;
    netAmount: number;
    couponDiscount: number;
    promotionDiscount: number
    totalAfterPromotionDiscount: number;
    status: string;
    confirmedDate?: string;
    cancelledDate?: string;
    deliveredDate?: string;
    cancelRequestedDate?: string;
    rejectedCancellationDate?: string;
    estimateTimeDelivery: string;
    cancelRequested: string;
    total_quantity: number;
    items: PurchaseOrderItem[];
    purchaseOrderItems: PurchaseOrderItem[];
    purchaseOrderActions: PurchaseOrderActions[];
    promotions: PromotionResponse[];
}

export class PurchaseOrderImpl implements PurchaseOrder {
    id: number;
    stockistName: string;
    stockistAddress: string;
    amount: number;
    purchaseOrderNumber: string;
    createdAt: string;
    shipFromName: string;
    retailerName: string;
    shippingAddress: string;
    totalAmount: number;
    shippingCost: number;
    netAmount: number;
    couponDiscount: number;
    promotionDiscount: number;
    totalAfterPromotionDiscount: number;
    status: string;
    confirmedDate?: string;
    cancelledDate?: string;
    deliveredDate?: string;
    cancelRequestedDate?: string;
    rejectedCancellationDate?: string;
    estimateTimeDelivery: string;
    cancelRequested: string;
    total_quantity: number;
    purchaseOrderItems: PurchaseOrderItem[];
    purchaseOrderActions: PurchaseOrderActions[];
    items: PurchaseOrderItem[];
    promotions: PromotionResponse[]

    constructor(data: any) {
        this.id = data.id;
        this.stockistName = data.stockistName;
        this.stockistAddress = data.stockistAddress;
        this.amount = data.amount;
        this.purchaseOrderNumber = data.purchaseOrderNumber;
        this.createdAt = data.createAt || '';
        this.shipFromName = data.shipFromName || '';
        this.retailerName = data.retailerName || '';
        this.shippingAddress = data.shippingAddress || '';
        this.totalAmount = data.totalAmount || 0;
        this.shippingCost = data.shippingCost || 0;
        this.netAmount = data.netAmount || 0;
        this.couponDiscount = data.couponDiscount || 0;
        this.promotionDiscount = data.promotionDiscount || 0;
        this.totalAfterPromotionDiscount = data.totalAfterPromotionDiscount ||0;
        this.status = data.status || '';
        this.confirmedDate = data.confirmedDate || '';
        this.cancelledDate = data.cancelledDate || '';
        this.deliveredDate = data.deliveredDate || '';
        this.cancelRequestedDate = data.cancelRequestedDate || '';
        this.rejectedCancellationDate = data.rejectedCancellationDate || '';        this.estimateTimeDelivery = data.estimateTimeDelivery || '';
        this.cancelRequested = data.cancelRequested || '';
        this.total_quantity = data.total_quantity || '';
        this.purchaseOrderItems = data.purchaseOrderItems?.map(
            (x: PurchaseOrderItem) => new PurchaseOrderItem(x),
        );
        this.purchaseOrderActions = data.purchaseOrderActions?.map(
            (x: PurchaseOrderActions) => new PurchaseOrderActions(x),
        );
        this.items = data.items?.map(
          (x: PurchaseOrderItem) => new PurchaseOrderItem(x),
        );
        this.promotions = data.promotions
    }
}
export interface reOrderPayload {
  confirm: boolean
}
