import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Product } from '@core/models/retailer/product.model';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CartPayload,
  IItemProductPayload,
  IProductMyCart,
} from '@core/models/retailer/cart.model';
import { ModalMessageComponent } from '../../modal-message/modal-message.component';
import { ButtonTag } from '@core/models/shares/enum';
import { debounceTime, Subject} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-my-cart-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class MyCartProductComponent implements OnInit, OnDestroy {
  @Input() isManageMode = false;
  @Input() product: IProductMyCart | undefined;
  @Output() productSelected: EventEmitter<IItemProductPayload> =
    new EventEmitter<IItemProductPayload>();
  @Output() productChanged: EventEmitter<CartPayload> =
    new EventEmitter<CartPayload>();

  constructor(
    private modalService: NgbModal,
    private changeDetection: ChangeDetectorRef,
    private translate: TranslateService
  ) { }
  private modalRef: NgbModalRef | undefined;
  showModal = false;
  modalOpened = false;
  currentLang = 'th'


  @ViewChild('currentQuantity') currentQuantityInput: ElementRef | undefined;

  private updateSubject = new Subject<CartPayload>();
  ngOnInit(): void {
    this.currentLang = this.translate.currentLang
    this.updateSubject.pipe(
      debounceTime(500)
    ).subscribe(updateData => this.productChanged.emit(updateData));
  }

  ngOnDestroy(): void {
    this.updateSubject.unsubscribe();
  }

  calculateTotalPrice() {
    if (this.product) return this.product.price * this.product.quantity;
    return 0;
  }

  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value.replace(/[^0-9]/g, '');
    inputElement.value = inputValue;
  }

  handleOnBlur() {
    if (!this.product) return;
    this.modalOpened = false;

    if (this.currentQuantityInput) {
      const inputElement = this.currentQuantityInput.nativeElement;
      const inputValue = inputElement.value;
      if (/[^0-9]/.test(inputValue)) {
        inputElement.value = inputValue.replace(/[^0-9]/g, '');
      }
      const numericValue = inputElement.value;

      if (!inputValue) {
        this.openModal();
      } else {
        this.product.quantity = numericValue;
        const updateData: CartPayload = {
          quantity: this.product.quantity,
          stockistProductId: this.product.stockistProductId,
        };
        this.productChanged.emit(updateData);
        if (numericValue !== '') {
          this.showModal = false;
        }
      }
    }
    if (this.product?.quantity === 0) {
      this.openModal();
    }
  }

  openModal() {
    if (!this.product) return;
    this.modalRef = this.modalService.open(ModalMessageComponent);
    this.modalRef.componentInstance.modalConfig = {
      colorButton: ButtonTag.Success,
      title: this.translate.instant('CART.CLOSE'),
      message: `${this.translate.instant('CART.BIRNG')} ${this.product.productName}\n${this.translate.instant('CART.OUT')}`,
      confirmLabel: this.translate.instant('ACTION.CONFIRM'),
      cancelLabel: this.translate.instant('TITLE.CANCEL'),
    };
    this.modalRef.result.then(
      (confirm) => {
        if (confirm) {
          if (!this.product) return;
          this.product.quantity = 0;
          const updateData: CartPayload = {
            quantity: this.product.quantity,
            stockistProductId: this.product.stockistProductId,
          };
          this.productChanged.emit(updateData);
        } else {
          if (this.currentQuantityInput) {
            if (!this.product) return;
            this.currentQuantityInput.nativeElement.value = 1;
            this.product.quantity = 1
            const updateData: CartPayload = {
              quantity: this.product.quantity,
              stockistProductId: this.product.stockistProductId,
            };
            this.productChanged.emit(updateData);
          }
        }
      },
      (error) => {
        if (error) {
          if (!this.product) return;
          this.product.quantity == -''.trim()
            ? this.product.quantity++
            : this.product.quantity;
        }
      }
    );
    this.modalOpened = true;
  }


  increaseQuantity(): void {
    if (!this.product) return;
    this.product.quantity = this.product.quantity + 1
    const updateData: CartPayload = {
      quantity: this.product.quantity,
      stockistProductId: this.product.stockistProductId,
    };
    this.updateSubject.next(updateData);
    if (this.product.quantity > 0) {
      this.showModal = false;
    }
  }

  reduceQuantity(): void {
    if (!this.product) return;
    if (this.product.quantity > 1) {
      this.product.quantity--
      const updateData: CartPayload = {
        quantity: this.product.quantity,
        stockistProductId: this.product.stockistProductId,

      };
      this.updateSubject.next(updateData);

    } else {
      this.openModal();
    }
  }

  handleSelectedProduct(): void {
    if (!this.product) return;
    const data: IItemProductPayload = {
      price: this.product.price,
      productId: this.product.productId,
      stockistProductId: this.product.stockistProductId,
      quantity: this.product.quantity,
    };
    this.productSelected.emit(data);
  }

  mockIncreaseQuantity(): void {
    if (!this.product) return;
    ++this.product.quantity;
  }

  mockReduceQuantity(): void {
    if (!this.product) return;
    if (this.product.quantity > 0) {
      --this.product.quantity;
    }
  }

  handleImageError(event: any) {
    event.target.src = 'assets/images/image_error.jpg';
  }
}

export interface IProductSelection extends Product {
  selected: boolean;
}
