<div class="aura-container-custom">
    <div class="pl-1">
        <div class="w-100 search-box" (click)="navigateToSearch()">
            <app-input-search placeholder="{{ 'PLACEHOLDER.SEARCH' | translate }}"></app-input-search>
        </div>
    </div>
    <div class="carousel-area">
        <ngb-carousel>
            <ng-template ngbSlide *ngFor="let item of bannerList; let i = index">
                <div class="picsum-img-wrapper banner-area" (click)="onClickBanner(item)">
                    <img class="banner-custom" [src]="item.imagePath" alt="slide banner" />
                </div>
            </ng-template>
        </ngb-carousel>

        <div class="loading-section" *ngIf="isLoadingBanner">
            <div class="spinner-border"></div>
        </div>
    </div>

    <div class="category">
        <div class="favButton container-fluid">
            <!-- <button
                class="btn categoryButton opacity-50 border-0"
                [disabled]="true"
            >
                <div class="fixIcon">
                    <img src="assets/icons/favIcon.svg" alt="" /><span
                        >รายการโปรด</span
                    >
                </div>
            </button> -->
            <button class="full btn categoryButton" routerLink="/retailer/order-history">
                <div class="fixIcon full">
                    <div>
                        <img src="assets/icons/hisIcon.svg" alt="" />
                    </div>
                    <div class="pt-2">{{ "ACTION.HISTORY" | translate }}</div>
                </div>
            </button>
        </div>
    </div>

    <div class="aura-sub-container-custom content-area">
        <div class="container-category">
            <div class="shelve category-gap">
                <div *ngFor="let item of allCategory | slice: 0 : 10; let i = index">
                    <app-card-category [item]="item" (click)="navigateToCategory(item.id)" />
                </div>
            </div>
        </div>
    </div>

    <div class="title-card d-flex justify-content-between">
        <div>{{ "TITLE.RECOMMEND_BRAND" | translate }}</div>
        <button class="titleButton" routerLink="/retailer/all-recommend-brands">
            {{ "ACTION.ALL" | translate }} &nbsp;<i class="fas fa-arrow-right"></i>
        </button>
    </div>
    <app-recommend-brand />

    <div *ngFor="let showCase of showCaseList; let i = index">
        <div class="title-card d-flex justify-content-between">
            <div>{{ currentLang === 'th' ? showCase.name : showCase.nameEN}}</div>
            <button class="titleButton" (click)="navigateToShowCaseDetail(showCase.id)">
                {{ "ACTION.ALL" | translate }} &nbsp;<i class="fas fa-arrow-right"></i>
            </button>
        </div>
        <div class="aura-sub-container-custom content-area">
            <div class="shelve d-flex gap-3">
                <ng-container *ngIf="!showCase.isLoading && showCase.showCaseProduct">
                    <div class="card-box-position" *ngFor="
                            let item of showCase.showCaseProduct
                                | slice: 0 : 10;
                            let i = index
                        " [attr.data-cy-row-number]="i + 1">
                        <app-card-item-product class="w-fit" [item]="item" />
                    </div>
                    <div class="no-data-section" *ngIf="showCase.showCaseProduct.length === 0">
                        <img src="assets/icons/Not_Found_Item_Icon.svg" />
                    </div>
                </ng-container>

                <div class="no-data-section" *ngIf="!showCase.isLoading && !showCase.showCaseProduct">
                    <img src="assets/icons/Not_Found_Item_Icon.svg" />
                </div>

                <div class="loading-section" *ngIf="showCase.isLoading">
                    <div class="spinner-border"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="title-card d-flex justify-content-between">
        <div>{{ "TITLE.PRODUCT" | translate }}</div>
        <button class="titleButton" routerLink="/retailer/all-product">
            {{ "ACTION.ALL" | translate }} &nbsp;<i class="fas fa-arrow-right"></i>
        </button>
    </div>

    <div class="aura-sub-container-custom content-area">
        <div class="shelve d-flex gap-3">
            <div class="card-box-position" *ngFor="let item of productList | slice: 0 : 10; let i = index"
                [attr.data-cy-row-number]="i + 1">
                <app-card-item-product class="w-fit" [item]="item" />
            </div>

            <div class="no-data-section" *ngIf="!isLoadingProduct && stockistList.length === 0">
                <img src="assets/icons/Not_Found_Item_Icon.svg" />
            </div>

            <div class="loading-section" *ngIf="isLoadingProduct">
                <div class="spinner-border"></div>
            </div>
        </div>
    </div>

    <div class="recommendArea">
        <div class="title-card d-flex justify-content-between">
            <div>{{ "TITLE.STOCKIST" | translate }}</div>
        </div>
        <div class="recommendedItem d-flex gap-3 justify-content-start">
            <div class="banner" *ngFor="let item of stockistList | slice: 0 : 10; let i = index">
                <app-card-stockist (clickStockist)="navigateToStockist($event)" [item]="item" />
            </div>

            <div class="no-data-section" *ngIf="!isLoadingStockist && stockistList.length === 0">
                <img src="assets/icons/Not_Found_Item_Icon.svg" />
            </div>

            <div class="loading-section" *ngIf="isLoadingStockist">
                <div class="spinner-border"></div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="aura-container-custom footer-position" *ngIf="showButtonMyCart()">
  <button data-cy="show-my-cart-button" class="btn btn-view-product" (click)="goToMyCart()">ดูสินค้าในรถเข็น
    ({{formatCurrency(cartAmount)}})</button>
</div> -->