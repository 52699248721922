import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListNotificationResponse, NotificationDetail, NotificationParams, unreadNotification } from '@core/models/retailer/notification.model';
import { environment } from '@environments/environment';
import { ClearParams } from '@modules/shared/clear-params';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
export interface Pagination{
  page: number;
  limit: number;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  basePath = `${environment.apiNotificationUrl}`;
  private notificationCount = new BehaviorSubject(0);
  public currentItemCount = this.notificationCount.asObservable();

  constructor(private http: HttpClient) {}

  getNotificationCount(): Observable<unreadNotification> {
    return this.http.get<unreadNotification>(
      `${this.basePath}/notifications/unread/count`
    );
  }

  getNotifications( params: NotificationParams ): Observable<ListNotificationResponse> {
    return this.http.get<ListNotificationResponse>(
        `${this.basePath}/notifications`,
        {
            params: ClearParams(params),
        }
    );
  }

  readNotification( id: number ): Observable<null> {
    return this.http.patch<null>(
        `${this.basePath}/notifications/${id}/read`, null
    );
  }

  getNotificationDetail( id: number ): Observable<NotificationDetail> {
    return this.http.get<NotificationDetail>(
        `${this.basePath}/notifications/${id}`
    );
  }

  ////////////////////////////////////////////////////////////////////////////////

  getMyNotificationCount(): Observable<unreadNotification> {
    return this.http.get<unreadNotification>(`${this.basePath}/notifications/unread/count`).pipe(
        tap((response) => {
            if (response) {
              const count = response.unreadNotification;
              this.notificationCount.next(count);
            }
        }),
        map((response) => response)
    );
}

}
