import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Profile } from '@core/models/retailer/profile.model';
import { ProfileService } from '@core/services/retailer/profile.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalMessageComponent } from '../modal-message/modal-message.component';
import { ButtonTag } from '@core/models/shares/enum';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  currentLang!: string
  constructor(
    private location: Location,
    private profileService: ProfileService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private route: Router,
  ) { }

  retailerProfile?: Profile;
  linkedLine = false
  modalRef?: NgbModalRef

  ngOnInit(): void {
    const isThai = this.translate.currentLang === 'th';
    this.days = isThai ? this.thDays : this.enDays
    this.profileService.getProfile().subscribe({
      next: (res) => {
        this.retailerProfile = res
        this.linkedLine = res.verifyLineAccount
      }, error: (err) => { console.error(err) }
    }
    )
  }
  days: string[] = []
  thDays: string[] = ['จ', 'อ', 'พ', 'พฤ', 'ศ', 'ส', 'อา'];
  enDays: string[] = [
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sun',
  ]

  colors: string[] = [
    '#21C063',
    '#21C063',
    '#21C063',
    '#21C063',
    '#21C063',
    '#21C063',
    '#21C063',
  ];

  isDayActive(day: string, officeDays: string | undefined): boolean {

    if (!officeDays) {
      return false;
    }
    let d = ''
    if (day === 'Sun' || day === 'อา'){
      d = 'อา'
    }
    if (day === 'Mon' || day === 'จ'){
      d = 'จ'
    }
    if (day === 'Tue' || day === 'อ'){
      d = 'อ'
    }
    if (day === 'Wed' || day === 'พ'){
      d = 'พ'
    }
    if (day === 'Thu' || day === 'พฤ'){
      d = 'พฤ'
    }
    if (day === 'Fri' || day === 'ศ'){
      d = 'ศ'
    }
    if (day === 'Sat' || day === 'ส'){
      d = 'ส'
    }


    return new Set(officeDays.split('|')).has(d);
  }

  goBack(): void {
    // this.location.back();
    this.route.navigate(['/products'])
  }

  getAddress() {
    return this.retailerProfile?.address + " " + this.retailerProfile?.subDistrict + " " + this.retailerProfile?.district + " " + this.retailerProfile?.province + " " + this.retailerProfile?.postCode
  }

  getBillingAddress() {
    return this.retailerProfile?.billingAddress + " " + this.retailerProfile?.billingSubdistrict + " " + this.retailerProfile?.billingDistrict + " " + this.retailerProfile?.billingProvince + " " + this.retailerProfile?.billingPostCode
  }

  formatTime(startTime: string | undefined, endTime: string | undefined): string {
    const isThai = this.translate.currentLang === 'th';
    if (startTime && endTime) {
      const formattedStartTime = startTime.split("T")[1].substring(0, 5);
      const formattedEndTime = endTime.split("T")[1].substring(0, 5);
      if (isThai) {
        return `${formattedStartTime} - ${formattedEndTime} น.`;
      } else {
        return `${formattedStartTime} - ${formattedEndTime}`;
      }
    }
    return '';
  }

  onClickLinkLine() {
    this.profileService.getLinkAuth().subscribe({
      next: (res) => {
        window.location.href = res.url;
      }, error: (err) => { console.error(err) }
    })
  }

  async onClickUnlinkLine() {
    const result = await this.openUnLinkModal()
    if (result) {
      this.profileService.unlinkLine().subscribe({
        next: () => {
          window.location.reload()
        }, error: (err) => { console.error(err) }
      })
    }

  }

  async openUnLinkModal() {
    this.modalRef = this.modalService.open(ModalMessageComponent);
    this.modalRef.componentInstance.modalConfig = {
      colorButton: ButtonTag.Success,
      title: this.translate.instant('PROFILE.MODAL'),
      message: this.translate.instant('PROFILE.MODAL_1'),
      confirmLabel: this.translate.instant('ACTION.CONFIRM'),
      cancelLabel: this.translate.instant('ACTION.CANCEL'),
    };
    const confirm: boolean = await this.modalRef.result;
    return confirm;
  }
}
