import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Stockist } from '@core/models/retailer/stockist.model';
import { AzureBlobService } from '@core/services/retailer/azure-blob.service';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-card-stockist',
    templateUrl: './card-stockist.component.html',
    styleUrls: ['./card-stockist.component.scss'],
})
export class CardStockistComponent implements OnInit {
    @Input() item: Stockist | undefined;
    @Output() clickStockist: EventEmitter<number> = new EventEmitter<number>()

    imagePath = '';

    constructor(private azureBlobService: AzureBlobService) {}

    async ngOnInit(): Promise<void> {
        if (this.item) {
            const stkID = this.item.id;
            const image = this.item.logoPathThump.split("/").pop();
            this.imagePath = `${environment.apiLoginUrl}/stockists/logo/${stkID}/${image}`
        }
    }

    async ImageurlToFile(filePath: string) {
        if (!filePath) return;
        if (filePath.includes('http')) {
            this.imagePath = filePath;
            return;
        }
        this.azureBlobService
            .getLogoImageStockists(filePath)
            .subscribe((res: File) => {
                const customFile: File = res as File;
                this.displayImage(customFile);
            });
    }

    displayImage(file: File) {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result) {
                const arrayBuffer = reader.result as ArrayBuffer;
                const valueToAdd = arrayBuffer || '';
                this.imagePath = valueToAdd.toString();
            }
        };
        reader.readAsDataURL(file);
    }

    onClickStockist(stockistId:number) {
        this.clickStockist.emit(stockistId)
    }
}
