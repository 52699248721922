/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CartPayload, ICart } from '@core/models/retailer/cart.model';
import { ProductDetailDataInterface, ProductParams, ProductStockist } from '@core/models/retailer/product.model';
import { PromotionDetail, discountTier } from '@core/models/retailer/promotion.model';
import { AzureBlobService } from '@core/services/retailer/azure-blob.service';
import { CartService } from '@core/services/retailer/cart.service';
import { ProductService } from '@core/services/retailer/product.service';
import { PromotionService } from '@core/services/retailer/promotion.service';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss'],
})
export class ProductDetailComponent implements OnInit {
    @ViewChild('currentQuantity') currentQuantityInput: ElementRef | undefined;

    data?: ProductDetailDataInterface;
    promotionDetail?: PromotionDetail;
    quantity = 1;
    getCartItemForCheck: ICart = {} as ICart;
    currentQuantityProductInCart = 0;
    productNumber_incart = '';
    productImage = ''
    storeImage = ''
    checkProduct = false;
    loading = false;
    productList: ProductStockist[] = [];
    params: ProductParams = {
        limit: 200,
    };
    currentLang!: string

    constructor(
        private azureBlobService: AzureBlobService,
        private activeRoute: ActivatedRoute,
        private router: Router,
        private productService: ProductService,
        private cartService: CartService,
        private promotionService: PromotionService,
        private location: Location,
        private spinner: NgxSpinnerService,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.currentLang = this.translate.currentLang;
        this.activeRoute.params.subscribe((params) => {
            const productNumber = params['product-number'];
            // this.productNumber_incart = productNumber;
            this.getProductById(productNumber);
            this.getPromotionByProductId(productNumber);
        });

        // Scroll to the top of the page when navigation ends
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                window.scrollTo(0, 0);
            }
        });
    }

    @HostListener('input', ['$event'])
    onInput() {
        if (this.currentQuantityInput) {
            const inputElement = this.currentQuantityInput.nativeElement;

            const inputValue = inputElement.value;
            if (/[^0-9]/.test(inputValue)) {
                inputElement.value = inputValue.replace(/[^0-9]/g, '');
            }
            const numericValue = Number(inputElement.value);
            this.quantity = isNaN(numericValue) ? 1 : numericValue;
        }
    }

    handleImgError(event: ErrorEvent): void {
        (event.target as HTMLImageElement).src =
            'assets/images/image-placeholder.png';
    }
    getProductById(id: number): void {
        this.loading = true;
        this.spinner.show('spinner');
        this.productService.getProductDetailById(id).subscribe({
            next: (response) => {
                if (response.product.productImages.length === 0 || response.product.productImages === null) {
                    response.product.productImages.push({
                        id: 0,
                        type: "CoverImage",
                        filePath: "assets/images/image_error.jpg",
                        filePathThump: "assets/images/image_error.jpg"
                    });
                }
                this.data = response;
                this.cartService.getMyCart().subscribe((res) => {
                    const quantity = res
                        .find((x) => x.stockistName === response.stockist.name)
                        ?.items.find((i) => i.productId === response.product.id)?.quantity;
                    if (quantity) {
                        this.quantity = quantity;
                    }
                });
                const stkID = response.stockist.id;
                const image = response.stockist.logoPath.split("/").pop();
                this.storeImage = `${environment.apiLoginUrl}/stockists/logo/${stkID}/${image}`
                this.checkProduct = true
            },
            error: (err) => {
                if (err.status === 404) {
                    this.checkProduct = false;
                }
                this.loading = false;
                this.spinner.hide('spinner');
            },
            complete: () => {
                this.loading = false;
                this.spinner.hide('spinner');
            }
        });
    }

    getPromotionByProductId(productId: number): void {
        this.promotionService.getPromotionsByProductId(productId).subscribe((res) => {
            this.promotionDetail = res
        })
    }

    increaseQuantity(): void {
        this.quantity++;
    }

    decreaseQuantity(): void {
        if (this.quantity > 0) {
            this.quantity--;
        }
    }
    goBack(): void {
        this.location.back();
    }

    updateCartItem(): void {
        if (this.data) {
            const updateItems: CartPayload[] = [{
                stockistProductId: this.data?.id,
                quantity: this.quantity,
            }];
            this.cartService.updateCartItem(updateItems).subscribe(() => {
                this.cartService.getMyCart().subscribe()
                // this.router.navigate(['retailer/products']);
                this.location.back();  //Navigate to the previous page
            });
        }
    }

    formatDateThai(value: string) {
        const monthsThaiShort = [
            "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
            "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
        ];

        const monthEnNames = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        const date = new Date(value);
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear() + 543;
        const isThai = this.translate.currentLang === 'th';
        const monthName = isThai ? monthsThaiShort[month] : monthEnNames[month];
        return `${day} ${monthName} ${year}`;
    }

    async ImageurlToFile(filePath: string, type: 'Product' | 'Store') {
        if (!filePath) return;
        if (filePath.includes('http')) {
            if (type === 'Product') {
                this.productImage = filePath;
            } else {
                this.storeImage = filePath;
            }
        }
        this.azureBlobService
            .getLogoImageStockists(filePath)
            .subscribe((res: File) => {
                const customFile: File = res as File;
                this.displayImage(customFile, type);
            });
    }
    displayImage(file: File, type: 'Product' | 'Store') {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.result) {
                const arrayBuffer = reader.result as ArrayBuffer;
                const valueToAdd = arrayBuffer || '';
                if (type === 'Product') {
                    this.productImage = valueToAdd.toString();
                } else {
                    this.storeImage = valueToAdd.toString();
                }
            }
        };
        reader.readAsDataURL(file);
    }

    getDiscountPrice(discounts: discountTier[] | undefined) {
        if (discounts && discounts.length > 0) {
            const lastDiscount = discounts[discounts.length - 1];
            return lastDiscount.type === 'Percent' ? `${lastDiscount.discount}%` : `${lastDiscount.discount}฿`;
        }
        return null;
    }

    handleImageError(event: any) {
        event.target.src = 'assets/images/image_error.jpg';
    }

    handleClickProduct(stockistProductId: number | undefined) {
        if (stockistProductId) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.router.navigate([`retailer/product-detail/${stockistProductId}`]);
        }
    }

    checkProductImage() {
        if (this.data) {
            if (this.data.product.productImages.length > 1) {
                return true;
            }
        }
        return false;
    }
}
