<div *ngFor="let product of products" class="product-item-share">
    <img *ngIf="product.quantity !== 0" alt="Product image" class="product_image" width="52px" height="52px" [src]="product.coverImagePathThump"
        onerror="this.src='assets/images/image_error.jpg'" />
    <div *ngIf="product.quantity !== 0">
        <p class="product-name fs-sm">
            {{ currentLang === 'th' ? product.productName : product.productNameEn }}
        </p>
        <div class="group-price">
            <p class="product-name fs-sm fw-semibold mb-0">
                X{{ product.quantity }}
            </p>
            <span class="price fs-6 fw-semibold">
                <div class="product-price">
                    <span class="discount-price" *ngIf="product.promotionDiscount"> {{ product.amount | currency : 'THB' : 'symbol-narrow' }}</span>
                    <span>{{ product.promotionDiscount? (product.totalAfterPromotionDiscount| currency : 'THB' : 'symbol-narrow') : product.amount | currency : 'THB' : 'symbol-narrow' }} </span>
                </div>
            </span>
        </div>
    </div>
</div>
