import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import {
    AuthUser,
    OTPResponse,
    PhoneVerify,
    SetPasswordPayload,
    credential,
} from '@core/models/auth/auth-user.model';
import { APIResponse } from '@core/models/shares/http.model';
import { environment } from '@environments/environment';
import { Observable, lastValueFrom, map, of } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private http: HttpClient,
        private authUser: UserService,
        private jwtHelper: JwtHelperService
    ) { }

    login(email: string, password: string): Observable<APIResponse<AuthUser>> {
        return this.http
            .post<APIResponse<AuthUser>>(
                `${environment.apiUrl}/authorization-service/v0/sign-in/email`,
                {
                    email,
                    password,
                }
            )
            .pipe(
                map((res) => {
                    this.authUser.setUser(res.data);
                    return res;
                })
            );
    }
    // Login Retailer New FLow /////////////////////////////////////////////////////
    retailerLogin(username: string, password: string): Observable<credential> {
        return this.http.post<credential>(
            `${environment.apiLoginUrl}/retailer/login`,
            {
                username,
                password,
            }
        );
    }

    phoneVerify(mobilePhoneNumber: string): Observable<PhoneVerify> {
        return this.http.post<PhoneVerify>(
            `${environment.apiLoginUrl}/retailer/verify-phone`,
            {
                mobilePhoneNumber,
            }
        );
    }

    requestOtp(mobilePhoneNumber: string): Observable<OTPResponse> {
        return this.http.post<OTPResponse>(
            `${environment.apiLoginUrl}/retailer/request-otp`,
            {
                mobilePhoneNumber,
            }
        );
    }

    verifyOtp(otp: string, refCode: string): Observable<PhoneVerify> {
        return this.http.post<PhoneVerify>(
            `${environment.apiLoginUrl}/retailer/verify-otp`,
            {
                otp,
                refCode,
            }
        );
    }

    setPassword(passwordData: SetPasswordPayload): Observable<credential> {
        return this.http.post<credential>(
            `${environment.apiLoginUrl}/retailer/set-password`,
            passwordData
        );
    }

    //////////////////////////////////////////////////////////////////

    setToken(token: string, refreshtoken: string) {
        // localStorage.clear();
        localStorage.removeItem('token');
        localStorage.removeItem('refreshtoken');
        const local = window.localStorage.getItem('local')
        if (local) {
            localStorage.setItem('local', local)
        }
        localStorage.setItem('token', token);
        localStorage.setItem('refreshtoken', refreshtoken);
        const jwtDecode = this.jwtHelper.decodeToken(token);
        const user = {
            profile: {
                type:
                    jwtDecode.groups && jwtDecode.groups.length > 0
                        ? jwtDecode.groups[0].toUpperCase()
                        : 'UNKNOWN',
                email: jwtDecode.email,
            },
        };
        localStorage.setItem('user', JSON.stringify(user));
    }

    permissionAuth() {
        const token = localStorage.getItem('token');
        if (token) {
            const jwtDecode = this.jwtHelper.decodeToken(token);
            localStorage.setItem('user', jwtDecode);

            return jwtDecode;
        } else {
            console.error('no token');
        }
    }
    refreshToken(): Observable<credential> {
        const refreshToken = localStorage.getItem('refreshtoken');
        return this.http.post<credential>(`${environment.apiUrl}/retailer/refresh`, { refreshToken });
    }

    async refreshTokenAsync(): Promise<credential> {
        return lastValueFrom(this.refreshToken());
    }

    isTokenExpired(): Observable<boolean> {
        const token = localStorage.getItem('token');
        return of(this.jwtHelper.isTokenExpired(token));
    }

    getNewAccessToken(refreshToken: string): Observable<APIResponse<AuthUser>> {
        return this.http
            .put<APIResponse<AuthUser>>(
                `${environment.apiUrl}/authorization-service/v0/token`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${refreshToken}`,
                    },
                }
            )
            .pipe(
                map((res) => {
                    this.authUser.setUser(res.data);
                    return res;
                })
            );
    }
    loginretailer(
        countryCode: string,
        phoneNumber: string,
        password: string
    ): Observable<APIResponse<AuthUser>> {
        return this.http
            .post<APIResponse<AuthUser>>(
                `${environment.apiUrl}/authorization-service/v0/sign-in/phoneNumber`, // Mock up
                {
                    countryCode,
                    phoneNumber,
                    password,
                }
            )
            .pipe(
                map((res) => {
                    this.authUser.setUser(res.data);
                    return res;
                })
            );
    }

    onBoarding(passwordData: SetPasswordPayload): Observable<credential> {
        return this.http.post<credential>(
            `${environment.apiLoginUrl}/retailer/onboard`,
            passwordData
        );
    }
}
