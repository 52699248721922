<div id="myModal" class="modal">
    <div id="animate" class="modal-reorder">
        <div class="header">
            <span class="title">{{ "TITLE.EXAMINE_PRODUCT" | translate }}</span>
            <i class="fa-solid fa-xmark" (click)="close()"></i>
        </div>
        <div class="container mt-3">
            <div class="row mb-5">
              <div *ngFor="let product of products" class="product-item-share align-items-start ">
                <ng-container >
                  <div class="image-container">
                    <img alt="Product image" [src]="product.coverImagePathThump"
                      onerror="this.src='assets/images/image_error.jpg'" />
                      <div  *ngIf="product.valid" class="text-overlay">{{ "TITLE.EMPTY" | translate }}</div>
                  </div>
                  <div class="group-name mt-2">
                    <p class="product-name fs-sm text-left" [ngClass]="{'text-faded': product.valid}">
                      {{ product.productName }}
                    </p>
                  </div>
                  <div>
                      <div class="group-quantity mt-2">
                        <div class="box-quantity" [ngClass]="{'text-faded': product.valid}">
                          <p class="quantity  fw-semibold" [ngClass]="{'text-faded': product.valid}">
                            X{{ product.quantity }}
                          </p>
                        </div>

                      </div>
                  </div>
                </ng-container>
              </div>

                <div class="fixed-bottom">
                  <div class="bottom p-3" >
                    <button (click)="addToCart()"
                        class="rounded-3 d-flex p-2 justify-content-center align-items-center gap-2 flex-fill"
                        style="width: 100%;">
                        {{ "ACTION.ADD_CART" | translate }}
                    </button>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>
