import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IItemProductPayload } from '@core/models/retailer/cart.model';
import { IOrderDetail } from '@core/models/retailer/order-preview.model';
import { reOrderProducts } from '@core/models/retailer/purchase-order.model';
import { RetailerOrderStatus } from '@core/models/retailer/retailer-enum';
import { PurchaseOrderService } from '@core/services/retailer/purchase.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalMessageAlertComponent } from '../modal-message-alert/modal-message-alert.component';
import { TranslateService } from '@ngx-translate/core';

interface ErrorResponse {
  error: string | reOrderProducts[]
}

interface ProductReorder {
  productId: number;
  price: number;
  stockistProductId: number;
  quantity: number;
}
@Component({
    selector: 'app-order-history-detail-deprecated',
    templateUrl: './order-history-detail-deprecated.component.html',
    styleUrls: ['./order-history-detail-deprecated.component.scss'],
})
export class OrderHistoryDetailDeprecatedComponent {
    @Input() orderDetail: IOrderDetail | undefined
    purchaseOrderNumber = '';
    retailerPurchaseOrderNumber = '';
    isAlreadyConfirmOrder = false;
    currentDate: Date = new Date();
    orderStatus: typeof RetailerOrderStatus = RetailerOrderStatus;
    isDeliveryLate = false;
    addressRetailer = '';
    openReorderModal = false
    reOrderProducts: reOrderProducts[] = [];

    constructor(
      private modalService: NgbModal,
      private router: Router,
      private purchaseOrderService: PurchaseOrderService,
      private translate: TranslateService
    ){}
    private modalRef: NgbModalRef | undefined;

    sumTotalQuantity(): number {
        if (this.orderDetail && this.orderDetail.items) {
            return this.orderDetail.items.reduce(
                (prev, item) => prev + item.quantity,
                0
            );
        } else {
            return 0;
        }
    }

    getAddress() {
        return this.orderDetail?.billingAddress + " " + this.orderDetail?.billingSubdistrict + " " + this.orderDetail?.billingDistrict + " " + this.orderDetail?.billingProvince + " " + this.orderDetail?.billingPostCode
    }

    navigateToOrderHistory() {
        window.history.back();
        setTimeout(() => {
            window.location.reload()
        },100)
    }

    navigateToPurchaseOrderCancel() {
        // this.dataService.updateData('purchaseOrderCancellation', {
        //     purchaseOrderNumber: this.purchaseOrder?.purchaseOrderNumber,
        //     retailerPurchaseOrderNumber: this.retailerPurchaseOrderNumber,
        //     status: this.purchaseOrder?.status,
        //     name: this.purchaseOrder?.shipFromName,
        // });
        // this.router.navigate(['retailer/confirm-order-cancellation']);
    }

    addItemToCart(): void {
      if (
        !this.orderDetail ||
        this.orderDetail.items.length === 0
        ) {
            return;
        }

        const data = {
          confirm: false
        }

        let product: ProductReorder = {
          productId: 0,
          price: 0,
          stockistProductId: 0,
          quantity: 0
        }

        const products: IItemProductPayload[] =[]

        this.purchaseOrderService.reOrderPurchase(this.orderDetail.id, data).subscribe({
          next: (res) => {

            for (const item of res) {
              product = {
                productId: item.productId,
                stockistProductId: item.stockistProductId,
                price: item.price,
                quantity: item.quantity
              }

              products.push(product)

            }

            localStorage.setItem('productData', JSON.stringify(products));

            this.router.navigate(['/retailer/my-cart'])
          },
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          error: (error: ErrorResponse) => {
            if (typeof(error.error) === 'string') {
              this.modalRef = this.modalService.open(ModalMessageAlertComponent, {
                centered: true,
                backdropClass: 'custom-modal-backdrop'
              });
              this.modalRef.componentInstance.modalConfig = {
                message: this.translate.instant('ORDER_ERROR.ERROR_PRODUCT')
              };
              return
            } else {
              this.reOrderProducts = error.error
              this.openReorderModal = true
            }
          },
      });
    }

    isDeliveryDelayed(){
        // const etd = this.purchaseOrder?.estimateTimeDelivery;
        // if (!etd) return false;

        // const date = new Date();
        // date.setDate(date.getDate() - 1); // Yesterday

        // return date.getTime() > new Date(etd).getTime();
    }

    isPurchaseOrderHaveGiftPromotion(order: IOrderDetail): boolean {
        if (order.promotions.length > 0) {
            return order.promotions.some((promotion) => promotion.promotionType === "Gift");
        }
        return false;
    }
}
