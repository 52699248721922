import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IBrandRecommend, IBrandRecommendDataTable } from '@core/models/retailer/brand.model';
import { BrandService } from '@core/services/retailer/brand.service';

const mockData: IBrandRecommendDataTable = {
  data: [
    {
      id: 1,
      name: "ParkStockist",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509226/CoverImage/0f105151-3078-4372-a8c2-81e012585c89.png",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 37,
      name: "Stockist Test",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509226/CoverImage/0f105151-3078-4372-a8c2-81e012585c89.png",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 99,
      name: "ร้านนี้จน ไม่ซ้ำใคร",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509226/CoverImage/0f105151-3078-4372-a8c2-81e012585c89.png",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 101,
      name: "PoonSri ห้ามลบ เข้าใจป่ะ",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509226/CoverImage/0f105151-3078-4372-a8c2-81e012585c89.png",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 170,
      name: "ร้าน no33",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509225/CoverImage/ffe917c6-afc2-4eeb-95b9-7c1a018ddd29.png",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 195,
      name: "อุลตร้าฝนค้าส่ง",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509225/CoverImage/ffe917c6-afc2-4eeb-95b9-7c1a018ddd29.png",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 203,
      name: "แรน",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509225/CoverImage/ffe917c6-afc2-4eeb-95b9-7c1a018ddd29.png",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 230,
      name: "pippa Poon 🔥",
      logoUrlThump: "",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 256,
      name: "เป็ดค้าส่ง",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509225/CoverImage/ffe917c6-afc2-4eeb-95b9-7c1a018ddd29.png",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 28202,
      name: "บ้านนอกคอกนา",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509225/CoverImage/ffe917c6-afc2-4eeb-95b9-7c1a018ddd29.png",
      nameEn: '',
      logoPath: ''
    }
  ],
  total: 10,
  totalPage: 1,
  page: 1,
  limit: 10,
  offset: 0
}

export interface BrandParams {
  limit: number
  page: number
  keywords?: string
}

@Component({
  selector: 'app-all-recommend-brands',
  templateUrl: './all-recommend-brands.component.html',
  styleUrls: ['./all-recommend-brands.component.scss']
})
export class AllRecommendBrandsComponent implements OnInit {
  params: BrandParams = {
    limit: 50,
    page: 1,
  };

  brandList: IBrandRecommend[] = []
  total = 0
  hasMoreData = true
  showNotFoundIcon = false

  constructor(private route: Router, private brandService: BrandService) { }

  ngOnInit(): void {
    this.loadData()
  }

  loadData() {
    this.brandList = mockData.data
  }

  navigateBack(): void {
    this.route.navigate(['/all-product']);
  }

  navigateToBrandProduct(brandId: number) {
    this.route.navigate([`/retailer/all-product`, { 'brand-id': brandId }]);
  }

  search(query: string): void {
    this.params = {
      ...this.params,
      limit: 50,
      page: 1,
      keywords: query,
    };
    this.brandList = [];
    this.handleSearch();
  }

  handleSearch() {
    this.brandService
      .getRecommendBrand(this.params)
      .subscribe((res) => {
        this.total = res.total;
        if (Array.isArray(res.data)) {
          if (res.data.length === 0) {
            this.hasMoreData = false;
          } else {
            const newProducts = res.data
            const existingIds = this.brandList.map(
              (item) => item.id
            );
            const uniqueNewProducts = newProducts.filter(
              (item) => !existingIds.includes(item.id)
            );
            this.brandList.push(...uniqueNewProducts);
          }
          this.showNotFoundIcon = this.brandList.length === 0;
        }
      });
  }

  showProductList(): boolean {
    return this.brandList.length > 0;
  }

  showNotFoundMessage(): boolean {
    return this.showNotFoundIcon && !this.showProductList();
  }
}
