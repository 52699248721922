<div class="row gap-2 align-items-end mt-2 mb-4">
    <div class="d-flex justify-content-between">
        <p>{{ "PAYMENT.TOTAL_ALL" | translate }} ({{ paymentInfo.totalQuantity }} {{ "TITLE.ITEM" | translate }})</p>
        <p data-cy="totalProductPrice">
            {{ paymentInfo.totalAmount | currency : 'THB' : 'symbol-narrow' }}
        </p>
    </div>
    <div *ngIf="paymentInfo.promotionDiscount > 0" class="d-flex justify-content-between">
        <p>{{ "PAYMENT.PROMOTION_DISCOUNT" | translate }}</p>
        <p>
            {{ paymentInfo.promotionDiscount | currency : 'THB' : 'symbol-narrow' }}
        </p>
    </div>
    <div *ngIf="paymentInfo.couponDiscount > 0" class="d-flex justify-content-between">
        <p>{{ "PAYMENT.PROMOTION_COUPON" | translate }}</p>
        <p>
            {{ paymentInfo.couponDiscount | currency : 'THB' : 'symbol-narrow' }}
        </p>
    </div>
    <div class="d-flex justify-content-between">
        <p>{{ "PAYMENT.SHIPPING_COST" | translate }}</p>
        <p>
            {{ paymentInfo.shippingCost | currency : 'THB' : 'symbol-narrow' }}
        </p>
    </div>
    <div class="d-flex justify-content-between fw-semibold">
        <p>{{ "PAYMENT.NET_TOTAL" | translate }}</p>
        <p data-cy="totalPrice">
            {{ paymentInfo.netAmount | currency : 'THB' : 'symbol-narrow' }}
        </p>
    </div>
</div>
