<div class="modal-wrapper">
    <div class="modal-content" (click)="openModal()">
        <div class="modal-header">
            <h5 class="modal-title">{{ 'MODAL_COUPON.TITLE' | translate}}</h5>
            <button type="button" class="close" (click)="closeModal()">
                <span>&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <input type="text" [(ngModel)]="code" placeholder="{{ 'MODAL_COUPON.PLACEHOLDER' | translate }}" class="input-code" (input)="checkCoupon()"/>
        </div>
        <div class="modal-error">
            <pre class="modal-error-text">{{error}}</pre>
        </div>
        <div class="modal-footer">
            <button *ngIf="!isNotUse"  type="button" class="btn btn-apply" [disabled]="code === ''"
            (click)="applyDiscount()">{{ 'MODAL_COUPON.APPLY' | translate}}</button>

            <button *ngIf="isNotUse" type="button" class="btn btn-not-use" (click)="notUseDiscount()">{{ 'MODAL_COUPON.ACTION' | translate}}</button>
        </div>
    </div>
</div>
