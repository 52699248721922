<div class="remove-padding-top">
  <div class="grey-background"></div>
  <div class="aura-container-custom " [ngStyle]="{ 'background-color': !isEmpty ? '#FFFFFF' : '' }">
    <nav id="header-page" class="navbar bg-body-tertiary mb-md-4">
      <div class="px-0">
        <div class="back-button justify-content-start pointer-cursor " (click)="goToProductsPage()">
          <i class="fa-solid fa-arrow-left" style="width: 2.25rem" aria-hidden="true"></i>
          <span>{{ "ACTION.BACK_PAGE" | translate }}</span>
        </div>
      </div>
    </nav>
    <div class="d-flex justify-content-between fs-hd mt-3 w-100 mb-0" *ngIf="!isEmpty">
      <p class="myCart">{{ 'CART.TITLE' | translate}}</p>
      <button class="manage-cart" (click)="manageCart()" [disabled]="isEmpty">{{isManageMode ? ('TITLE.CANCEL' | translate) :
        ('ACTION.MANAGE' | translate) }}</button>
    </div>
    <div class="border-bottom remove-padding" *ngIf="!isEmpty"></div>


    <div id="body" class="body-pane" [hidden]="isEmpty">
      <app-my-cart-stockist *ngFor="let stockist of cart" [stockistId]="stockist.stockistId"
        [stockistName]="stockist.stockistName" [productList]="stockist.items"
        (stockistSelectd)="onSelectStockist($event)" (productSelected)="onSelectProduct($event)"
        (productChanged)="onProductChanges($event)" [isManageMode]="isManageMode"
        [checkedAllStockist]="checkedAllStockist"></app-my-cart-stockist>
    </div>
  </div>
  <div *ngIf="isLoading" class="overlay">
    <ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false" name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
  </div>

  <div *ngIf="!isLoading">
    <div [hidden]="!isEmpty">
      <div class="emptyBg d-flex justify-content-center body-pane">
        <img src="assets/images/Empty_Cart_Image.svg" alt="assets/images/image_error.jpg" />
        <span class="head fs-hd pt-3">{{ 'CART.EMPTY_CART1' | translate}}</span>
        <span class="desc fs-md">{{ 'CART.EMPTY_CART2' | translate}}</span>
      </div>
    </div>

    <div class="margin-top-before-footer pt-5" [hidden]="isEmpty"></div>
    <div id="footer-page" class="footer footer-position" [hidden]="isEmpty">
      <div class="col w-100 px-3" *ngIf="!isManageMode">
        <div class="d-flex justify-content-between fs-5 fw-semibold px-2" *ngIf="isButtonEnabled()">
          <div class="">
            <p data-cy="label-total-amount">{{ 'CART.TOTAL_NET' | translate}}</p>
          </div>
          <div class="">
            <p data-cy="total-amount">
              {{ calculateTotalPrice() | currency : "THB" : "symbol-narrow" }}
            </p>
          </div>
        </div>
        <button data-cy="review-cart-button" type="button" class="btn btn-block confirm-order-btn"
          [disabled]="!isButtonEnabled()" (click)="onReviewOrder()">
          {{ 'CART.CHECK_ORDER' | translate}}
        </button>
      </div>

      <div class="col w-100 px-3" *ngIf="isManageMode">
        <div class="d-flex justify-content-between align-items-center">
          <div class="col d-flex align-items-center checkbox">
            <input type="checkbox" id="checkedAll-checkbox" [(ngModel)]="checkedAllStockist"
              (check)="checkedAllStockist" (ngModelChange)="onSelectAll()">
            <label for="checkedAll-checkbox" class="me-2"></label>
            <label class="select-all-item fw-rg">{{ 'CART.SELECT_ALL' | translate}}</label>
          </div>
          <div>
            <button type="button" class="btn btn-block delete-btn" (click)="onDeleteOrder()"
              [disabled]="!isButtonEnabled()">{{ 'CART.DEL' | translate}}</button>
          </div>
        </div>
      </div>
    </div>

    <div [hidden]="!isEmpty" style="bottom: 5rem; margin-top: 6rem;">
      <div class="col w-100 px-3">
        <button type="button" class="btn btn-block confirm-order-btn" (click)="goToProductsPage()">
          {{ 'CART.GO_PRODUCT' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
