import { Component, OnInit, OnDestroy } from '@angular/core';
import {
    CartPayload,
    IItemProductPayload,
    IMyCart,
    IPayloadOrder,
    IValidateMainPayload,
    IValidateOrderPayload,
    IValidatePayload,
} from '@core/models/retailer/cart.model';
import { Product } from '@core/models/retailer/product.model';
import { Router } from '@angular/router';
import { CartService } from '@core/services/retailer/cart.service';
import { NgxSpinnerService } from 'ngx-spinner';

export interface CheckStockist {
    stockistsId: number;
    checked: boolean;
}

export interface UpdateCartAmount {
    productId: number;
    newQuntity: number;
}

@Component({
    selector: 'app-my-cart',
    templateUrl: './my-cart.component.html',
    styleUrls: ['./my-cart.component.scss'],
})
export class MyCartComponent implements OnInit, OnDestroy {
    constructor(
        private route: Router,
        private cartService: CartService,
        private spinner: NgxSpinnerService
    ) { }
    isEmpty = true;
    cart: IMyCart[] = [];
    totalCart = 0;
    stockistProductList: IStockistProduct[] = [];
    selectedProduct: IValidateOrderPayload[] = [];
    reOrderProducts: IItemProductPayload[] = [];
    isManageMode = false;
    checkedAllStockist = false;
    isOnInit = false;
    isLoading = false;

    ngOnDestroy(): void {
        window.localStorage.removeItem('productData');
    }

    ngOnInit(): void {
        this.reOrderProducts = JSON.parse(
            localStorage.getItem('productData') ?? '[]'
        );
        this.isLoading = true
        this.spinner.show('spinner');
        this.getMyCart();
    }

    goToProductsPage() {
        this.route.navigate(['retailer/products']);
    }

    goToReviewOrderPage() {
        this.route.navigate(['retailer/review-order']);
    }

    updateCartAmout(amount: number): number {
        return (this.totalCart = amount);
    }

    getMyCart() {
        this.cartService.getMyCart().subscribe((res) => {
            const oldData = this.cart;
            this.cart = res;
            oldData.forEach((oldOrder) => {
                const indexStockist = this.cart.findIndex(
                    (x) => x.stockistId === oldOrder.stockistId
                );
                if (indexStockist !== -1) {
                    oldOrder.items.forEach((oldProduct) => {
                        const indexProduct = this.cart[
                            indexStockist
                        ].items.findIndex(
                            (product) =>
                                product.stockistProductId ===
                                oldProduct.stockistProductId
                        );
                        if (indexProduct !== -1) {
                            this.cart[indexStockist].items[
                                indexProduct
                            ].isSelected = oldProduct.isSelected;
                        }
                    });
                }
            });
            if (!this.isOnInit) {
                const rawData = localStorage.getItem('orderList')
                let data: IValidateMainPayload | undefined
                if (rawData) {
                    data = JSON.parse(rawData);
                }
                if (data) {
                    data.orders.forEach((reviewData) => {
                        const indexStockist = this.cart.findIndex(
                            (cartData) =>
                                cartData.stockistId === reviewData.stockistId
                        );
                        if (indexStockist !== -1) {
                            reviewData.items.forEach((productReview) => {
                                const indexProduct = this.cart[
                                    indexStockist
                                ].items.findIndex(
                                    (product) =>
                                        product.productId ===
                                        productReview.productId
                                );
                                if (indexProduct !== -1) {
                                    this.cart[indexStockist].items[
                                        indexProduct
                                    ].isSelected = true;
                                }
                            });
                        }
                    });
                    localStorage.removeItem('orderList');
                }

            }

            if (res && res.length > 0) {
                this.isEmpty = false;
            } else {
                this.isEmpty = true;
            }

            this.checkedAllStockist = this.cart.every((stockist) =>
                stockist.items.every((product) => product.isSelected === true)
            );

            if (!this.isOnInit) {
                if (this.reOrderProducts.length > 0) {
                    for (const item of this.reOrderProducts) {
                        this.onSelectProduct(item);
                    }
                }
            }

            this.isLoading = false
            this.spinner.hide('spinner');
            this.isOnInit = true;
        });
    }

    onSelectStockist(stockistsData: CheckStockist) {
        const index = this.cart.findIndex(
            (x) => x.stockistId === stockistsData.stockistsId
        );
        if (index !== -1) {
            if (!stockistsData.checked) {
                this.cart[index].items.forEach((item) => {
                    item.isSelected = true;
                });
            } else {
                this.cart[index].items.forEach((item) => {
                    item.isSelected = false;
                });
            }
        }
        this.checkedAllStockist = this.cart.every((stockist) =>
            stockist.items.every((product) => product.isSelected === true)
        );
    }

    onSelectProduct(productData: IItemProductPayload) {
        const indexStockists = this.cart.findIndex((i) =>
            i.items.find(
                (o) => o.stockistProductId === productData.stockistProductId
            )
        );

        const indexProduct = this.cart[indexStockists].items.findIndex(
            (i) => i.stockistProductId === productData.stockistProductId
        );

        const isSelected =
            this.cart[indexStockists].items[indexProduct].isSelected;
        this.cart[indexStockists].items[indexProduct].isSelected = !isSelected;
        if (!this.isManageMode) {
            this.updateCartAmout(this.calculateTotalPrice());
        }
    }

    onSelectAll() {
        this.cart.forEach((stockist) => {
            stockist.items.forEach((item) => {
                item.isSelected = this.checkedAllStockist;
            });
        });
    }

    onProductChanges(updateAmount: CartPayload) {
        const indexStockists = this.cart.findIndex((i) =>
            i.items.find(
                (o) => o.stockistProductId === updateAmount.stockistProductId
            )
        );
        const indexProduct = this.cart[indexStockists].items.findIndex(
            (i) => i.stockistProductId === updateAmount.stockistProductId
        );
        this.cart[indexStockists].items[indexProduct].quantity =
            updateAmount.quantity;
        this.updateCartAmout(this.calculateTotalPrice());
        const payload = [updateAmount];
        this.cartService.updateCartItem(payload).subscribe(() => {
            this.getMyCart();
        });
    }

    calculateTotalPrice(): number {
        return this.cart.reduce((totalPrice, stockistProduct) => {
            return stockistProduct.items
                .filter((data) => data.isSelected)
                .reduce((subtotal, product) => {
                    return subtotal + product.price * product.quantity;
                }, totalPrice);
        }, 0);
    }

    onReviewOrder() {
        const dataForCheck: IValidatePayload[] = [];
        const stockistsData = this.cart.filter((i) =>
            i.items.some((b) => b.isSelected === true)
        );
        stockistsData.forEach((stockist) => {
            const itemFiltered = stockist.items
                .filter((product) => product.isSelected === true)
                .map((filtedProduct) => {
                    return {
                        price: filtedProduct.price,
                        productId: filtedProduct.productId,
                        quantity: filtedProduct.quantity,
                    } as IPayloadOrder;
                });
            const dataToAdd: IValidatePayload = {
                items: itemFiltered,
                stockistId: stockist.stockistId,
            };
            dataForCheck.push(dataToAdd);
        });
        const payload = {
            coupon: null,
            orders: dataForCheck,
        };
        localStorage.setItem('orderList', JSON.stringify(payload));
        this.goToReviewOrderPage();
    }

    onDeleteOrder() {
        let dataForCheck: CartPayload[] = [];
        const stockistsData = this.cart.filter((i) =>
            i.items.some((b) => b.isSelected === true)
        );
        stockistsData.forEach((stockist) => {
            const itemFiltered = stockist.items
                .filter((product) => product.isSelected === true)
                .map((filtedProduct) => {
                    return {
                        stockistProductId: filtedProduct.stockistProductId,
                        quantity: 0,
                    } as CartPayload;
                });
            dataForCheck = dataForCheck.concat(itemFiltered);
        });
        this.cartService.updateCartItem(dataForCheck).subscribe(() => {
            this.getMyCart();
        });
    }

    manageCart() {
        this.isManageMode = !this.isManageMode;
        this.cart.forEach((stockist) => {
            stockist.items.forEach((item) => {
                item.isSelected = false;
            });
        });
        this.checkedAllStockist = false;
    }
    isButtonEnabled(): boolean {
        return this.cart.some((stockist) =>
            stockist.items.some((item) => item.isSelected)
        );
    }
}

export interface IStockistProduct {
    stockistId: number;
    stockistName: string;
    items: Product[];
}

export class StockistProduct implements IStockistProduct {
    stockistId: number;
    stockistName: string;
    items: Product[];

    constructor(data?: IStockistProduct) {
        this.stockistId = data?.stockistId || 0;
        this.stockistName = data?.stockistName || '';
        this.items = data?.items || [];
    }
}

export type StockistProducts = {
    stockistId: number;
    products: Product[];
};
