<div class="layer-notify" >
    <nav class="navigate-bar">
        <div class="back-container" (click)="goToProductsPage()">
            <i class="fa-solid fa-arrow-left" aria-hidden="true"></i>
            <label for="backforward">{{ "ACTION.BACK_PAGE" | translate }}</label>
        </div>
    </nav>
    <ngx-spinner  size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false" name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
    <div  class="d-flex flex-column mt-3 mx-3 notifyList" aria-hidden="true" *ngIf="loading_data">
      <div class="row">
        <div class="col-12 img-potion" *ngIf="notification.imagePath !== '' && notification.imagePath !== null">
          <img
            class="img-notify"
            [src]="notification.imagePath"
            alt=""
            onerror="this.src='assets/images/image_error.jpg'"
          >
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 text-title">
          <label>{{ currentLang === 'th' ? notification.title : notification.titleEn }}</label>
        </div>
      </div>

      <div class="row mt-3 text-date">
        <div class="col-12">
          <label>{{ notification.createdAt | displayDate }}</label>
        </div>
      </div>

      <!-- <div class="row mt-3 text-detail">
        <div class="col-12">
          <label>{{ notification.description }}</label>
        </div>
      </div> -->

      <div class="row mt-4 text-detail-full">
        <div class="col-12">
          <label>
            {{ currentLang === 'th' ? notification.detail : notification.detailEn }}</label>
        </div>
      </div>


    </div>


</div>
