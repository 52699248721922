import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IBrandRecommendDataTable } from '@core/models/retailer/brand.model';

const mockData: IBrandRecommendDataTable = {
  data: [
    {
      id: 1,
      name: "ParkStockist",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509226/CoverImage/0f105151-3078-4372-a8c2-81e012585c89.png",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 37,
      name: "Stockist Test",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509226/CoverImage/0f105151-3078-4372-a8c2-81e012585c89.png",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 99,
      name: "ร้านนี้จน ไม่ซ้ำใคร",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509226/CoverImage/0f105151-3078-4372-a8c2-81e012585c89.png",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 101,
      name: "PoonSri ห้ามลบ เข้าใจป่ะ",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509226/CoverImage/0f105151-3078-4372-a8c2-81e012585c89.png",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 170,
      name: "ร้าน no33",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509225/CoverImage/ffe917c6-afc2-4eeb-95b9-7c1a018ddd29.png",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 195,
      name: "อุลตร้าฝนค้าส่ง",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509225/CoverImage/ffe917c6-afc2-4eeb-95b9-7c1a018ddd29.png",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 203,
      name: "แรน",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509225/CoverImage/ffe917c6-afc2-4eeb-95b9-7c1a018ddd29.png",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 230,
      name: "pippa Poon 🔥",
      logoUrlThump: "",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 256,
      name: "เป็ดค้าส่ง",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509225/CoverImage/ffe917c6-afc2-4eeb-95b9-7c1a018ddd29.png",
      nameEn: '',
      logoPath: ''
    },
    {
      id: 28202,
      name: "บ้านนอกคอกนา",
      logoUrlThump: "https://aurad.z23.web.core.windows.net/preview/Product/509225/CoverImage/ffe917c6-afc2-4eeb-95b9-7c1a018ddd29.png",
      nameEn: '',
      logoPath: ''
    }
  ],
  total: 10,
  totalPage: 1,
  page: 1,
  limit: 10,
  offset: 0
}

@Component({
  selector: 'app-recommend-brand',
  templateUrl: './recommend-brand.component.html',
  styleUrls: ['./recommend-brand.component.scss']
})

export class RecommendBrandComponent implements OnInit {
  recommendBrand?: IBrandRecommendDataTable
  isLoading = false

  constructor(private route: Router) { }
  ngOnInit(): void {
    this.loadData()
  }

  loadData() {
    this.isLoading = true
    this.recommendBrand = mockData
    this.isLoading = false
  }

  onClickBrand(brandId: number) {
    this.route.navigate([`/retailer/all-product`, { 'brand-id': brandId }]);
  }
}
