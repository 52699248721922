import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ConfirmOrderCancellationFromStatus,
  OrderCancellationFromPendingStatus,
} from '@core/models/retailer/confirm-order-cancellation.model';
import { PurchaseOrderService } from '@core/services/retailer/purchase.service';
import { DataService } from '@core/services/shared/data.service';

interface CancellationInfo {
  retailerPurchaseOrderNumber: string;
  purchaseOrderNumber: string;
  status: string;
  name: string;
}

@Component({
  selector: 'app-cancellation',
  templateUrl: './cancellation.component.html',
  styleUrls: ['./cancellation.component.scss'],
})
export class CancellationComponent implements OnInit {
  reason: string[] = [];
  otherReason = '';
  finalReason = '';
  checkedOtherCheckbox = false;
  isFormValid = false;
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private purchaseOrderService: PurchaseOrderService,
    private dataService: DataService
  ) { }
  pendingReasonsText = [
    OrderCancellationFromPendingStatus.wantToChangeQuantity,
    OrderCancellationFromPendingStatus.wantToChangeProduct,
    OrderCancellationFromPendingStatus.wantToProductUrgently,
    OrderCancellationFromPendingStatus.wantToChangeMind,
    OrderCancellationFromPendingStatus.other,
  ];

  confirmReasonsText = [
    ConfirmOrderCancellationFromStatus.wantToChangeQuantity,
    ConfirmOrderCancellationFromStatus.wantToChangeProduct,
    ConfirmOrderCancellationFromStatus.wantToProductUrgently,
    ConfirmOrderCancellationFromStatus.wantToChangeMind,
    ConfirmOrderCancellationFromStatus.other,
  ];
  cancellationInfo: CancellationInfo = {
    retailerPurchaseOrderNumber: '',
    purchaseOrderNumber: '',
    status: '',
    name: '',
  };

  ngOnInit(): void {
    this.dataService.getData<CancellationInfo>('purchaseOrderCancellation').subscribe(
      (res) => {
        this.cancellationInfo = res;
      },
      (err) => {
        console.error(err);
      }
    )
    // console.log(this.cancellationInfo);

    // this.activeRoute.queryParams.subscribe((params) => {
    //   this.cancellationInfo.retailerPurchaseOrderNumber =
    //     params['retailerPurchaseOrderNumber'];
    //   this.cancellationInfo.purchaseOrderNumber = params['purchaseOrderNumber'];
    //   this.cancellationInfo.status = params['status'];
    //   this.cancellationInfo.name = params['name'];
    // });
  }

  onClickCheckbox(index: number) {
    switch (this.cancellationInfo.status) {
      case 'Pending':
        this.addReasonToList(index, this.pendingReasonsText);
        break;
      case 'Confirmed':
        this.addReasonToList(index, this.confirmReasonsText);
        break;
    }
    this.updateFinalReason();
  }

  addReasonToList(index: number, reasonList: string[]) {
    if (this.reason[index] === undefined || this.reason[index] === null) {
      this.reason[index] = reasonList[index];
      this.isFormValid = true;
    } else {
      delete this.reason[index];
      this.isFormValid = this.reason.some(reason => reason !== undefined);
    }

    this.isClickLastCheckBox(index, reasonList);
  }

  isClickLastCheckBox(index: number, reasonList: string[]) {
    if (index === reasonList.length - 1) {
      this.checkedOtherCheckbox = !this.checkedOtherCheckbox;
    }
  }

  clearOtherReason() {
    this.otherReason = '';
  }

  transformReason(): string {
    let result = '';
    this.reason.forEach((item) => {
      result += item;
      result += '|';
    });

    if (this.otherReason) {
      result += this.otherReason;
      result += '|';
    }

    if (this.checkedOtherCheckbox) {
      this.isFormValid = this.otherReason !== ''
    }

    result = result.slice(0, -1);
    return result;
  }

  updateFinalReason() {
    this.finalReason = this.transformReason();
  }

  cancelOrder() {
    // this.updateFinalReason();
    // this.purchaseOrderService
    //   .cancelPurchaseOrder(
    //     this.cancellationInfo.retailerPurchaseOrderNumber,
    //     this.cancellationInfo.purchaseOrderNumber,
    //     {
    //       reason: this.finalReason,
    //     }
    //   )
    //   .subscribe((res) => {
    //     if (res.message === 'success') {
    //       this.router.navigate(['retailer/order-cancelled'], {
    //         queryParams: {
    //           purchaseOrderNumber: this.cancellationInfo.purchaseOrderNumber,
    //           status: this.cancellationInfo.status,
    //         },
    //       });
    //     }
    //   });
  }
}
