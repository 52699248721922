/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    IItemMyCart,
    IRetailerAddress,
    IValidateOrderPayload,
    IValidateResponse,
} from '@core/models/retailer/cart.model';
import { OrderPromotion } from '@core/models/retailer/order-preview.model';
import { OrderPromotionRespones } from '@core/models/retailer/promotion.model';
import { CartService } from '@core/services/retailer/cart.service';
import { PromotionService } from '@core/services/retailer/promotion.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-review-order',
    templateUrl: './review-order.component.html',
    styleUrls: ['./review-order.component.scss'],
})
export class ReviewOrderComponent implements OnInit {
    constructor(
        private route: Router,
        private cartService: CartService,
        private promotionService: PromotionService,
        private spinner: NgxSpinnerService,
        private translate: TranslateService

    ) {

    }

    isConfirmBtnDisabled = false;
    retailerData?: IRetailerAddress;
    validateDetail: IValidateResponse[] = [];
    couponName = '';
    discountCouponAmount = 0;
    discountCouponType = '';
    isModalOpen = false;
    selectedGiftAwayId: number[][] = [];
    giftAways: OrderPromotionRespones[][] = []
    isLoading = true;
    hasDiscountPromotion = false;
    hasGiftAways = false;
    currentLang = 'th'


    openDiscountModal() {
        this.isModalOpen = true;
    }

    closeDiscountModal() {
        this.isModalOpen = false;
    }

    handleDiscount(discount: number) {
        this.discountCouponAmount = discount;
        this.isModalOpen = false;
    }

    handleDiscountCode(code: string) {
        this.couponName = code;
    }

    handleDiscountType(type: string) {
        this.discountCouponType = type;
    }

    updateSelectedGiftAway(giftId: number, i: number, j: number): void {
        this.selectedGiftAwayId[i][j] = giftId;
    }

    ngOnInit() {
        this.currentLang = this.translate.currentLang
        this.getRetailerAddress();
        this.getValidateDetail();
    }

    setDefaultGiftAway() {
        if (Array.isArray(this.giftAways)) {
            for (let i = 0; i < this.giftAways.length; i++) {
                this.selectedGiftAwayId[i] = [];

                if (Array.isArray(this.giftAways[i])) {
                    for (let j = 0; j < this.giftAways[i].length; j++) {
                        const giftAwayItem = this.giftAways[i][j];

                        if (giftAwayItem && Array.isArray(giftAwayItem.giftAways) && giftAwayItem.giftAways.length > 0) {
                            this.selectedGiftAwayId[i].push(giftAwayItem.giftAways[0].id);
                        }
                    }
                }
            }
        }
    }


    formatCurrency(amount: number) {
        return new Intl.NumberFormat('th-TH', {
            style: 'currency',
            currency: 'THB',
        }).format(amount);
    }

    getRetailerAddress() {
        this.isLoading = true
        this.spinner.show('spinner');
        this.cartService.getRetailerAddress().subscribe({
            next: (res) => {
                this.retailerData = res;
                this.isLoading = false
                this.spinner.hide('spinner');
            },
        });
    }

    async getValidateDetail() {
        const stringData = localStorage.getItem('orderList');
        this.isLoading = true
        this.spinner.show('spinner');
        if (stringData) {
            const data: IValidateOrderPayload = JSON.parse(stringData);
            this.cartService.retailerOrderValidate(data).subscribe({
                next: (res) => {
                    this.validateDetail = res;
                    this.isLoading = false
                    this.spinner.hide('spinner');
                    res.map((order, index) => {
                        const itemInCart: OrderPromotion[] = [];
                        if (order.promotions && !this.hasDiscountPromotion) {
                            this.hasDiscountPromotion = order.promotions.some(({ discountCondition }) => ['Tier', 'Bundle'].includes(discountCondition));
                        }
                        if (order.promotions && !this.hasGiftAways) {
                            this.hasGiftAways = order.promotions.some(item => item.giftAways.length > 0);
                        }
                        order.items.map((item) => itemInCart.push({ productId: item.productId, price: item.price, quantity: item.quantity }));
                        this.promotionService.getOrderPromotion(order.stockistId, itemInCart).subscribe({
                            next: (res: OrderPromotionRespones[]) => {
                                this.giftAways[index] = res.filter((item)=> item.giftAways.length>0);
                                if (this.hasGiftAways) {
                                    this.setDefaultGiftAway();
                                }
                            }
                        });
                    });
                    if (res.some((i) => i.valid === false) || res.some(order => order.items.some(item => item.valid === false))) {
                        this.isConfirmBtnDisabled = false;
                    } else {
                        this.isConfirmBtnDisabled = true;
                    }
                },
            });
        }
    }

    handleImageError(event: any) {
        event.target.src = 'assets/images/image_error.jpg';
    }

    sumTotalPriceEachStore(item: IItemMyCart[]): number {
        let totalPriceEachStore = 0;
        for (const product of item) {
            totalPriceEachStore += product.totalPrice;
        }
        return totalPriceEachStore;
    }

    sumTotalDiscount(item: IItemMyCart[]): number {
        let totalDiscount = 0;
        for (const product of item) {
            if (product.promotionDiscount) {
                totalDiscount += product.promotionDiscount;
            }
        }
        return totalDiscount;
    }

    sumTotalPrice(): number {
        let totalPrice = 0;
        for (const data of this.validateDetail) {
            for (const product of data.items) {
                totalPrice += product.totalPrice;
            }
        }
        return totalPrice;
    }

    sumTotalDeliveryFee(): number {
        let totalDeliveryFee = 0;
        for (const data of this.validateDetail) {
            totalDeliveryFee += data.zone.deliveryFee;
        }
        return totalDeliveryFee;
    }

    sumTotalQuantity(): number {
        let totalQuantity = 0;
        for (const data of this.validateDetail) {
            for (const product of data.items) {
                totalQuantity += product.quantity;
            }
        }
        return totalQuantity;
    }

    sumTotalDiscountOrder(): number {
        let totalDiscount = 0;
        for (const data of this.validateDetail) {
            for (const product of data.items) {
                if (product.promotionDiscount) {
                    totalDiscount += product.promotionDiscount;
                }
            }
        }
        return totalDiscount;
    }

    createOrder() {
        const stringData = localStorage.getItem('orderList');
        if (stringData) {
            const coupon = this.couponName ? this.couponName : null;
            const dataOrder = JSON.parse(stringData);
            let data = {};

            const newOrder: IValidateOrderPayload = {
                coupon: coupon,
                orders: [],
            };
            for (const [index, order] of dataOrder.orders.entries()) {
                newOrder.orders?.push({
                    stockistId: order.stockistId,
                    items: order.items,
                    giveAwayIds: this.selectedGiftAwayId[index] ? this.selectedGiftAwayId[index] : []
                });
            }
            data = newOrder;
            this.isLoading = true
            this.spinner.show('spinner');
            this.cartService.retailerCreateOrder(data).subscribe({
                next: (res) => {
                    const convertedData = JSON.stringify(res);
                    localStorage.setItem('createOrder', convertedData);
                    // this.route.navigate(['/retailer/order-success']).then(() => {
                    //   window.location.reload();
                    // });
                    this.isLoading = false
                    this.spinner.hide('spinner');
                },
                complete: () => {
                    localStorage.removeItem('orderList');
                    this.route.navigate(['/retailer/order-success']).then(() => {
                        window.location.reload();
                    });
                    this.isLoading = false
                    this.spinner.hide('spinner');
                },
            });
        }
    }

    getAddress() {
        return (
            this.retailerData?.billingAddress +
            ' ' +
            this.retailerData?.billingSubdistrict +
            ' ' +
            this.retailerData?.billingDistrict +
            ' ' +
            this.retailerData?.billingProvince +
            ' ' +
            this.retailerData?.billingPostCode
        );
    }

    hasDiscountPromotionPerStockist(data: IItemMyCart[]) {
        return data.some(item => item.promotionDiscount !== 0);
    }

    hasGiftAwaysPerStockist(data: OrderPromotionRespones[]) {
        return data.some(item => item.giftAways.length > 0)
    }
}
