import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private dataStore: Map<string, BehaviorSubject<any>> = new Map();

    getData<T>(key: string): Observable<T> {
        if (!this.dataStore.has(key)) {
            this.dataStore.set(key, new BehaviorSubject<T | undefined>(undefined));
        }
        const dataSubject = this.dataStore.get(key);
        if (dataSubject) {
            return dataSubject.asObservable();
        }
        return new Observable<T>();
    }

    updateData<T>(key: string, data: T): void {
        if (!this.dataStore.has(key)) {
            this.dataStore.set(key, new BehaviorSubject<T>(data));
        } else {
            const dataSubject = this.dataStore.get(key);
            if (dataSubject) {
                dataSubject.next(data);
            } else {
                throw new Error(`Data subject not found for key: ${key}`);
            }
        }
    }

}
