import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RetailerPurchaseOrder } from '@core/models/retailer/retailer-purchase-order';
import { PurchaseOrderService } from '@core/services/retailer/purchase.service';
import { CartService } from '@core/services/retailer/cart.service';
import { delay, tap } from 'rxjs';
import { IRetailerAddress } from '@core/models/retailer/cart.model';
import { PurchaseOrder } from '@core/models/retailer/purchase-order.model';

@Component({
    selector: 'app-order-success',
    templateUrl: './order-success.component.html',
    styleUrls: ['./order-success.component.scss'],
})
export class OrderSuccessComponent implements OnInit, OnDestroy {
  orderProductNumber = 0;

    constructor(
        private purchaseOrderService: PurchaseOrderService,
        private cartService: CartService,
        private route: Router,
    ) {
    }

    retailerPurchaseOrder: RetailerPurchaseOrder = new RetailerPurchaseOrder({});
    retailerDetail?:IRetailerAddress

    ngOnDestroy(): void {
      window.localStorage.removeItem('createOrder')
    }

    ngOnInit() {
        this.cartService.getMyCart().subscribe()
        const orderData = window.localStorage.getItem('createOrder');

        if (orderData) {
          this.retailerPurchaseOrder = JSON.parse(orderData)
          this.cartService.getRetailerAddress().subscribe({next: (res) => {this.retailerDetail = res},error: (err) => {console.error(err)}})
          this.orderProductNumber = this.retailerPurchaseOrder.orders.length
          this.sum_data()
        } else {
          this.route.navigate(['/retailer']);
        }
        // this.route.params.subscribe((params) => {
        //     this.getRetailerPurchaseOrder(params['retailerPurchaseOrderNumber']);
        // });
    }

    isPurchaseOrderHaveGiftPromotion(order: PurchaseOrder): boolean {
      if (order.promotions.length > 0) {
          return order.promotions.some((promotion) => promotion.promotionType === "Gift");
      }
      return false;
  }

    navigateToMainPage() {
      this.cartService.getMyCart().subscribe()
      this.route.navigate(['retailer/products'])
    }

    sum_data() {
      this.retailerPurchaseOrder.totalAmount = 0
      this.retailerPurchaseOrder.shippingCost = 0
      this.retailerPurchaseOrder.couponDiscount = 0
      this.retailerPurchaseOrder.promotionDiscount = 0
      this.retailerPurchaseOrder.netAmount = 0

      this.retailerPurchaseOrder.total_quantity_all = 0

      this.retailerPurchaseOrder.orders.forEach(element => {

        element.total_quantity = 0

        this.retailerPurchaseOrder.totalAmount = Number(this.retailerPurchaseOrder.totalAmount.toFixed(2)) + Number(element.amount.toFixed(2))
        this.retailerPurchaseOrder.shippingCost = Number(this.retailerPurchaseOrder.shippingCost.toFixed(2)) + Number(element.shippingCost.toFixed(2))
        this.retailerPurchaseOrder.promotionDiscount = Number(this.retailerPurchaseOrder.promotionDiscount.toFixed(2)) + Number(element.promotionDiscount.toFixed(2))
        this.retailerPurchaseOrder.couponDiscount = Number(this.retailerPurchaseOrder.couponDiscount.toFixed(2)) + Number(element.couponDiscount.toFixed(2))
        this.retailerPurchaseOrder.netAmount = Number(this.retailerPurchaseOrder.netAmount.toFixed(2)) + Number(element.netAmount.toFixed(2))

        element.items.forEach(item => {

          element.total_quantity = Number(element.total_quantity.toFixed(0)) + Number(item.quantity.toFixed(0))

        });

        this.retailerPurchaseOrder.total_quantity_all = Number(this.retailerPurchaseOrder.total_quantity_all.toFixed(0)) + Number(element.total_quantity.toFixed(0))

      });

    }

    getRetailerPurchaseOrder(retailerPurchaseOrderNumber: string) {
        this.purchaseOrderService
            .getRetailerPurchaseOrder(retailerPurchaseOrderNumber)
            .pipe(
                delay(2000),
                tap(() => {
                    this.cartService.notifyCartChanged(); // call notifyCart here
                }),
            )
            .subscribe((x) => {
                this.retailerPurchaseOrder = x.data;
            });
    }

    formatCurrency(amount: number ) {
        if (amount) {
          return new Intl.NumberFormat('th-TH', {
              style: 'currency',
              currency: 'THB',
          }).format(amount);
        } else {
          return new Intl.NumberFormat('th-TH', {
            style: 'currency',
            currency: 'THB',
          }).format(0);
        }
    }

    getAddress() {
      return this.retailerDetail?.billingAddress + " " + this.retailerDetail?.billingSubdistrict + " " + this.retailerDetail?.billingDistrict + " " + this.retailerDetail?.billingProvince + " " + this.retailerDetail?.billingPostCode
  }
}
