<div *ngIf="product">
  <div class="product-pane">
    <div class="d-flex align-items-baseline" style="margin-right: auto">
      <div class="col-1 checkbox">
        <input [checked]="product.isSelected" (change)="handleSelectedProduct()" type="checkbox"
          id="product-{{product.stockistProductId}}-checkbox">
        <label for="product-{{ product.stockistProductId }}-checkbox" class="checkmark"></label>

      </div>
      <div class="">
        <img src="{{product.coverImagePathThump}}" (error)="handleImageError($event)" alt="product image" class="p-2 product_image">
      </div>
      <div class="w-100">
        <div class="row">
          <div class="col">
            <div class="product-name">
              <p class="pt-3 lh-base card-text-name">{{currentLang === 'th' ? product.productName : product.productNameEn}}</p>
            </div>
          </div>

        </div>
      </div>
      <!-- <ng-container *ngIf="showModal && !modalOpened">
        {{ openModal() }}
      </ng-container> -->
    </div>
    <div class="row">
      <div class="col-3"></div>
      <div class="col d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <button class="btn btn-icon" (click)="reduceQuantity()" [disabled]="isManageMode">
            <img src="assets/icons/MinusIcon.svg" />
          </button>
          <input #currentQuantity class="amount input-item" type="text" value="{{ product.quantity }}"
            oninput="this.value = this.value.replace(/^0+|[^0-9]|\b0+/g);" (input)="onInput($event)" (blur)="handleOnBlur()" [disabled]="isManageMode" />
          <button class="btn btn-icon" (click)="increaseQuantity()" [disabled]="isManageMode">
            <img src="assets/icons/PlusIcon.svg" />
          </button>
        </div>
        <span>
          <p class="fw-bold" style="margin-top: 0.6rem;">{{product.price * product.quantity|
            currency:'THB': 'symbol-narrow'}}</p>
        </span>
      </div>
    </div>
  </div>
