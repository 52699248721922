import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IOrderDetail } from '@core/models/retailer/order-preview.model';
import { PurchaseOrder } from '@core/models/retailer/purchase-order.model';
import { PurchaseOrderService } from '@core/services/retailer/purchase.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-order-history-detail-cancelrequested',
    templateUrl: './order-history-detail-cancelrequested.component.html',
    styleUrls: ['./order-history-detail-cancelrequested.component.scss'],
})
export class OrderHistoryDetailCancelrequestedComponent implements OnInit {
    @Input() orderDetail: IOrderDetail | undefined
    purchaseOrder: PurchaseOrder | null = null;
    purchaseOrderNumber = '';
    retailerPurchaseOrderNumber = '';
    currentDate: Date = new Date();
    addressRetailer = '';

    constructor(
        private purchaseOrderService: PurchaseOrderService,
        private activeRoute: ActivatedRoute,
        private router: Router,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.activeRoute.params.subscribe((params) => {
            this.retailerPurchaseOrderNumber =
                params['retailerPurchaseOrderNumber'];
            this.purchaseOrderNumber = params['purchaseOrderNumber'];
        });
        this.getPurchaseOrder();
    }
    getAddress() {
        return this.orderDetail?.billingAddress + " " + this.orderDetail?.billingSubdistrict + " " + this.orderDetail?.billingDistrict + " " + this.orderDetail?.billingProvince + " " + this.orderDetail?.billingPostCode
    }

    navigateToOrderHistory() {
        const page = window.localStorage.getItem('page');

        if (page) {
            if (page === 'notification') {
                this.router.navigate(['retailer/my-notification']);
            } else if (page === 'history') {
                this.router.navigate(['retailer/order-history']);
            }
        } else {
            this.router.navigate(['retailer/order-history']);
        }

        window.localStorage.removeItem('page')
    }

    getPurchaseOrder(): void {
        this.purchaseOrderService
            .getPurchaseOrder(
                this.retailerPurchaseOrderNumber,
                this.purchaseOrderNumber
            )
            .subscribe((res) => {
                this.purchaseOrder = res.data;
            });
    }

    sumTotalQuatity(): number {
        if (this.orderDetail && this.orderDetail.items) {
            return this.orderDetail.items.reduce(
                (prev, item) => prev + item.quantity,
                0
            );
        } else {
            return 0;
        }
    }

    formatTime(officeTime: string): string {
        const isThai = this.translate.currentLang === 'th';
        if (officeTime) {
            const [startTime, endTime] = officeTime.split("-");
            const formattedStartTime = startTime.split(" ")[1].substring(0, 5);
            const formattedEndTime = endTime.split(" ")[1].substring(0, 5);
            return isThai ? `${formattedStartTime} - ${formattedEndTime} น.` : `${formattedStartTime} - ${formattedEndTime}`;
        }
        return '';

    }

    isPurchaseOrderHaveGiftPromotion(order: IOrderDetail): boolean {
        if (order.promotions.length > 0) {
            return order.promotions.some((promotion) => promotion.promotionType === "Gift");
        }
        return false;
    }

    navigateToOldOrder() {
        this.router.navigateByUrl(`retailer/order-history/${this.orderDetail?.refOrderId}`).then(() => window.location.reload())
    }
}
