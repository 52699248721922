import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderPromotion } from '@core/models/retailer/order-preview.model';
import { OrderPromotionRespones, PromotionDetail} from '@core/models/retailer/promotion.model';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class PromotionService {
  basePath = `${environment.apiPromotionUrl}`;
  constructor(private http: HttpClient) {}

  getPromotionsByProductId(productId: number): Observable<PromotionDetail> {
    return this.http.get<PromotionDetail>(
      `${this.basePath}/promotions/product/${productId}/promotion`
    );
  }

  getOrderPromotion(stockistId: number , items: OrderPromotion[]){
    return this.http.post<OrderPromotionRespones[]>(
      `${this.basePath}/promotions/promotion` , {stockistId , items}
    )
  }
}
