/* eslint-disable @typescript-eslint/no-explicit-any */
import { ValidationErrors } from '@angular/forms';

export class ErrorMessage {
  getErrorMessage(error: ValidationErrors | any): string {
    const errorKey = Object.keys(error)[0];
    switch (errorKey) {
      case 'required':
        return 'กรุณากรอกข้อมูล';
      case 'minlength':
        return 'จำนวนตัวอักษรขั้นต่ำ' + error.minlength.requiredLength + 'ตัวอักษร';
      case 'maxlength':
        return 'จำนวนตัวอักษรไม่เกิน' + error.maxlength.requiredLength + 'ตัวอักษร';
      case 'email':
        return 'กรุณาตรวจสอบอีเมล';
      case 'mustMatch':
        return 'Pin ไม่ตรงกัน';
      case 'pattern':
        return 'กรุณาระบุเป็นตัวอักษร (a-z, A-Z, ก-ฮ) เท่านั้น';
      default:
        return this.getServerErrorMessage(error);
    }
  }

  getServerErrorMessage(value: any) {
    if (Array.isArray(value)) {
      const error = this.getErrorMessage(value[0]);
      if (!Array.isArray(error)) {
        return error;
      }
    } else {
      return value;
    }
  }
}
