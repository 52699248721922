import { Component, Input } from '@angular/core';
import { ButtonTag } from '@core/models/shares/enum';
import { ModalConfig } from '@core/models/shares/modal.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-modal-message-alert',
  templateUrl: './modal-message-alert.component.html',
  styleUrls: ['./modal-message-alert.component.scss']
})
export class ModalMessageAlertComponent {

  @Input() modalConfig: ModalConfig;

  constructor(private modalRef: NgbActiveModal) {
    this.modalConfig = {
      colorButton: ButtonTag.Primary,
      title: '',
      message: '',
      confirmLabel: '',
      cancelLabel: ''
    }

    setTimeout(() => {
      this.modalRef.close(false);
    }, 3000);
  }

  close() {
    this.modalRef.close(false);
  }

  confirm() {
    this.modalRef.close(true);
  }

  getButtonClasses(): string {
    switch (this.modalConfig.colorButton) {
      case ButtonTag.Success:
        return 'btn-primary-button';
      case ButtonTag.Danger:
        return 'btn-danger';
      case ButtonTag.Warning:
        return 'btn-warning';
      case ButtonTag.Info:
        return 'btn-info';
      case ButtonTag.Dark:
        return 'btn-dark';
      case ButtonTag.Light:
        return 'btn-light';
      case ButtonTag.Primary:
          return 'btn-primary';
      case ButtonTag.Secondary:
          return 'btn-secondary';
    }
  }

  getOutlineButtonClasses(): string {
    switch (this.modalConfig.colorButton) {
      case ButtonTag.Success:
        return 'btn-secondary-button';
      case ButtonTag.Danger:
        return 'btn-outline-danger text-danger';
      case ButtonTag.Warning:
        return 'btn-outline-warning text-warning';
      case ButtonTag.Info:
        return 'btn-outline-info text-info';
      case ButtonTag.Dark:
        return 'btn-outline-dark text-dark';
      case ButtonTag.Light:
        return 'btn-outline-light text-secondary';
      case ButtonTag.Primary:
          return 'btn-outline-primary text-primary';
      case ButtonTag.Secondary:
          return 'btn-outline-secondary text-secondary';
    }
  }
}


