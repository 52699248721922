import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationDetail, NotificationParams } from '@core/models/retailer/notification.model';
import { NotificationService } from '@core/services/retailer/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-my-notification-detail',
    templateUrl: './my-notification-detail.component.html',
    styleUrls: ['./my-notification-detail.component.scss'],
})
export class MyNotificationDetailComponent implements OnInit {
    currentLang! : string

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private notificationService: NotificationService,
        private spinner: NgxSpinnerService,
        private translate: TranslateService,
    ) {
    }

    notification: NotificationDetail = {createdAt: (new Date()).toString()};

    loading_data = false

    params: NotificationParams = {
        type: '',
        limit: 50,
        offset: 0,
        page: 1,
    };

    ngOnInit(): void {
        this.currentLang = this.translate.currentLang || 'en';
        this.notificationService.getMyNotificationCount().subscribe()
        this.getNotification();
    }

    getNotification() {
      this.spinner.show('spinner');

      const notificationId = this.route.snapshot.params['notificationId'];
      if (notificationId){
        this.notificationService.getNotificationDetail(notificationId).subscribe((res: NotificationDetail) => {
          this.notification = res
          this.loading_data = true
          this.spinner.hide('spinner');
        })
      }
    }

    goToProductsPage() {
      this.router.navigate(['retailer/my-notification']);
    }

}


