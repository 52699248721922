<div class="aura-container-custom" >
  <div class="grey-background"></div>
    <nav id="header-page" class="navbar bg-body-tertiary mb-4">
      <div class="px-3">
          <div class="back-button justify-content-start pointer-cursor " (click)="goToProductsPage()">
              <i class="fa-solid fa-arrow-left" style="width: 2.25rem" aria-hidden="true"></i>
              <span>{{ "ACTION.BACK_PAGE" | translate }}</span>
          </div>
      </div>
    </nav>
    <div class="aura-container-custom" >
        <div class="buttons-container" style="overflow-x: auto;">
            <button *ngFor="let type of notificationMenu" [class.active]="isActive(type)" (click)="setType(type)">
                {{ getTabTranslation(type, currentLang) }}
            </button>
        </div>
    </div>
    <ngx-spinner  size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false" name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
    <div class="align-items-center" *ngIf="!loading_data && this.notifications.length > 0" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()">
        <div *ngFor="let status of [
        { notifications: notifications ,page : 1, text: 'Order'},
        { notifications: notifications ,page : 2, text: 'News'},
        { notifications: notifications ,page : 3, text: 'Coupon'},
        { notifications: notifications ,page : 4, text: 'Promotion'},
        ]; let i = index">
            <ng-container *ngIf="activeType === status.text">
                <ng-container *ngFor="let notify of status.notifications" >
                    <div *ngIf="activeType === 'Order'"  class="d-flex flex-column mt-3 mx-3 notifyList" aria-hidden="true" (click)="onSelectNotification(notify.id, notify.orderId)">
                      <div class="row">
                        <div class="col-11 text-title">
                          <label>{{  currentLang === 'th' ? notify.title : notify.titleEn}}</label>
                        </div>

                        <div class="col-1" align="right">
                          <img
                            *ngIf="!notify.readAt"
                            class=""
                            src="assets/icons/Alert.svg"
                            width="12px"
                            height="12px"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="row mt-3 text-order-number">
                        <div class="col-12">
                          <label>{{ "TITLE.NUMBER" | translate }} : {{ notify.orderId }}</label>
                        </div>
                      </div>

                      <div class="row mt-2 text-detail">
                        <div class="col-12">
                          <label>{{ currentLang === 'th' ? notify.description : notify.descriptionEn }}</label>
                        </div>
                      </div>

                      <div class="row mt-3 text-date">
                        <div class="col-12">
                          <label>{{ notify.createdAt | displayDate }}</label>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="activeType !== 'Order'"  class="d-flex flex-column mt-3 mx-3 notifyList" aria-hidden="true" (click)="onSelectNotification(notify.id)">
                      <div class="row">
                        <div class="col-11 text-title">
                          <label>{{  currentLang === 'th' ? notify.title : notify.titleEn}}</label>
                        </div>

                        <div class="col-1" align="right">
                          <img
                            *ngIf="!notify.readAt"
                            class=""
                            src="assets/icons/Alert.svg"
                            width="12px"
                            height="12px"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="row mt-3 text-detail">
                        <div class="col-12">
                          <label>{{ currentLang === 'th' ? notify.description : notify.descriptionEn }}</label>
                        </div>
                      </div>

                      <div class="row mt-3 text-date">
                        <div class="col-12">
                          <label>{{ notify.createdAt | displayDate }}</label>
                        </div>
                      </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <div *ngIf="!loading_data && this.notifications.length === 0" class="empty-notify">
        <div class="content">
            <img  src="../../../../../../../assets/images/Empty_Notification_Image.png"
                alt="Empty history image">
            <p class="text-empty">{{ "NOTIFICATION.TITLE_EMPTY1" | translate }}</p>
            <p class="text-empty-sup">{{ "NOTIFICATION.TITLE_EMPTY2" | translate }}</p>
        </div>
    </div>
</div>