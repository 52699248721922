<nav
    class="navbar bg-body-tertiary"
    [ngStyle]="{
        'background-color': 'white',
        'padding-left': '1rem',
        'padding-right': '1rem',
        position: 'fixed',
        top: '10',
        width: '100%',
        'margin-top': '-1.125rem'
    }"
>
    <div
        class="back-button justify-content-start pointer-cursor"
        routerLink="/retailer/my-cart"
    >
        <i
            class="fa-solid fa-arrow-left"
            style="width: 2.25rem"
            aria-hidden="true"
        ></i>
        <span>{{ "ACTION.BACK_PAGE" | translate }}</span>
    </div>
</nav>

<div *ngIf="isLoading" class="overlay">
    <ngx-spinner bdColor="rgb(0,0,0,0.07)" size="medium" color="#21C063" type="ball-clip-rotate" [fullScreen]="false" name="spinner" style="margin-top: 0px !important;"></ngx-spinner>
</div>
<div class="aura-container-custom pt-4 px-0 pb-4" style="background-color: #FFFF;" *ngIf="!isLoading">
    <div class="grey-background"></div>
    <div
        class="d-flex align-items-center fs-4 px-3"
        [ngStyle]="{ width: '100%' }"
    >
        <p data-cy="label-page-info" class="fs-5 fw-semibold mb-0 me-auto">
          {{ "TITLE.CHECK_ORDER" | translate }}
        </p>
    </div>

    <div
        class="d-flex flex-column justify-content-center pt-3 gap-2" style="margin-bottom: 17rem;"
    >
        <div class="d-flex gap-2 fs-6 text-wrap px-3">
            <img
            class="mr-3"
            src="assets/images/shipaddress.svg"
            width="20px"
            height="20px"
            alt=""
          />
            <div>
                <p class="fs-sm fw-rg">{{ "TITLE.DELIVERED_AT" | translate }}</p>
                <div>
                    <span class="fs-md fw-md"
                        >{{ retailerData?.retailerName }}<br
                    /></span>
                    <span class="fs-sm fw-rg">
                        {{ getAddress() }}</span>
                </div>
            </div>
        </div>



        <dIv class="pb-3" *ngFor="let data of validateDetail ; let i = index">
            <div
                class="d-flex gap-2 fs-6 pt-2 text-wrap align-items-center top-border px-3"
            >
                <img src="assets\icons\Store_Icon.svg" alt="notFound" />
                <p class="store-name">{{ data.stockistName }}</p>
            </div>
            <div class="bottom-border"></div>

            <div *ngFor="let product of data.items" class="my-4">
                <div class="d-flex gap-3 text-wrap align-items-start px-3">
                    <img class="coverImg" width="80px" src="{{ product.coverImagePathThump }}" (error)="handleImageError($event)"
                        alt="notFound" />
                    <div class="w-100">
                        <p class="product-name">
                            {{ currentLang === 'th' ? product.productName : product.productNameEn }}
                        </p>
                        <div class="d-flex justify-content-between price-row">
                            <span>x{{product.quantity}}</span>
                            <div class="product-price">
                                <span class="discount-price" *ngIf="product.promotionDiscount">{{ formatCurrency(product.totalPrice)
                                    }}</span>
                                <span>{{ product.promotionDiscount?
                                    formatCurrency(product.totalAfterPromotionDiscountPrice):formatCurrency(product.totalPrice) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!product.valid" class="is-product-invalid d-flex gap-3 px-3 align-items-center">
                    <i ngClass="fa fa-exclamation-triangle"></i>
                    <p class="my-2">{{ currentLang === 'th' ?  product.errorMessage : product.errorMessageEn }}</p>
                </div>
            </div>            
            <div class="px-3 pt-3" *ngIf="giftAways[i] && giftAways[i].length > 0">
                <div *ngFor="let item of giftAways[i]; let j = index " class="d-flex flex-column w-100 justify-content-between select-gift mb-3">
                    <p class="mb-2" *ngIf="item.giftAways.length>0">{{ "TITLE.GIFT_2" | translate }}</p>
                    <label class="d-flex align-items-center gift-label mt-2" *ngFor="let gift of item.giftAways; let k = index">
                        <div>
                            <input type="radio" name="giftAwayOption{{i}}-{{j}}" [value]="gift.id" [(ngModel)]="selectedGiftAwayId[i][j]"
                            id="{{gift.id}}">
                            <span class="checkmark"></span>
                        </div>
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <div class="d-flex align-items-center">
                                <img class="coverImg " [src]="gift.coverImagePathThump" (error)="handleImageError($event)" alt="" width="50"
                                    height="50">
                                {{gift.name}}
                            </div>
                            <div class="align-item">{{gift.quantity}} {{ "TITLE.ITEM" | translate }}</div>
                        </div>
                    </label>
                </div>
            </div>

            <div
                class="d-flex gap-2 text-wrap align-items-start px-3 pt-3 sum-row"
            >
                <div class="w-100">
                    <div class="d-flex justify-content-between">
                        <p class="mb-2">{{ "PAYMENT.PRODUCT_TOTAL" | translate }}</p>
                        <span>{{ formatCurrency(sumTotalPriceEachStore(data.items)) }}</span>
                    </div>
                    <div *ngIf="hasDiscountPromotion && hasDiscountPromotionPerStockist(data.items)" class="d-flex justify-content-between">
                        <p class="mb-2">{{ "PAYMENT.PROMOTION_DISCOUNT" | translate }}</p>
                        <span>{{ formatCurrency(sumTotalDiscount(data.items)) }}</span>
                    </div>
                    <div class="d-flex justify-content-between">
                        <p>{{ "PAYMENT.SHIPPING_COST" | translate }}</p>
                        <span>{{ formatCurrency(data.zone.deliveryFee) }}</span>
                    </div>
                </div>
            </div>
            <div *ngIf="data.errorMessage" class="is-invalid d-flex gap-3 px-3 align-items-center">
                <i ngClass="fa fa-exclamation-triangle"></i>
                <p class="my-2">{{ currentLang === 'th' ?  data.errorMessage : data.errorMessageEn}}</p>
            </div>
        </dIv>

    </div>
</div>

<div id="footer-page" class="footer-position">
    <div class="d-flex flex-column w-100 px-3">
        <div class="d-flex justify-content-between fw-light pt-1 px-2" (click)="openDiscountModal()">
            <div class="fw-bold coupon">
                <img src="assets/icons/tag.png" /> {{ 'MODAL_COUPON.TITLE' | translate}}
            </div>
            <div class="d-flex gap-3 align-items-center">
                <div class="coupon-code">{{couponName}}</div>
                <i class="fa-solid fa-chevron-right" ></i>
            </div>
        </div>
        <hr/>
        <div class="d-flex justify-content-between fw-light pt-1 px-2">
            <div>
              {{ "PAYMENT.TOTAL_ALL" | translate }} ({{ sumTotalQuantity() }} {{ "TITLE.UNIT" | translate }})
            </div>
            <div>{{ formatCurrency(sumTotalPrice()) }}</div>
        </div>
        <div *ngIf="hasDiscountPromotion" class="d-flex justify-content-between fw-light pt-1 px-2">
            <div>
              {{ "PAYMENT.PROMOTION_DISCOUNT" | translate }}
            </div>
            <div>{{ formatCurrency(sumTotalDiscountOrder()) }}</div>
        </div>
        <div class="d-flex justify-content-between fw-light pt-1 px-2" *ngIf="discountCouponAmount !== 0">
            <div>{{ "PAYMENT.PROMOTION_COUPON" | translate }}</div>
            <div>{{ formatCurrency(discountCouponAmount) }}</div>
            <!-- <div *ngIf="discountCouponType === 'Percent'">%{{ discountCouponAmount }}</div> -->

        </div>
        <div class="d-flex justify-content-between fw-light pt-1 px-2">
            <div>{{ "PAYMENT.SHIPPING_COST" | translate }} ({{ "TITLE.ALL_STORE" | translate }})</div>
            <div>{{ formatCurrency(sumTotalDeliveryFee()) }}</div>
        </div>
        <div class="d-flex justify-content-between fs-5 fw-semibold py-3 px-2">
            <div>{{ "PAYMENT.NET_TOTAL" | translate }}</div>
            <div>{{ formatCurrency(sumTotalPrice()+sumTotalDeliveryFee()-discountCouponAmount-sumTotalDiscountOrder()) }}</div>
        </div>
        <button
            type="button"
            class="btn confirm-order-btn"
            [disabled]="!isConfirmBtnDisabled"
            (click)="createOrder()"
            data-cy="confirm-order-btn"
        >
        {{ "ACTION.CONFIRM_ORDER" | translate }}
        </button>
    </div>
    <app-modal-coupon
    *ngIf="isModalOpen"
    [dataOrder]="validateDetail"
    [totalPriceOrder]="sumTotalPrice()+sumTotalDeliveryFee()-sumTotalDiscountOrder()"
    [oldDiscountCode]="couponName"
    (discountApplied)="handleDiscount($event)"
    (discountCode)="handleDiscountCode($event)"
    (discountType)="handleDiscountType($event)"
    (modalClose)="closeDiscountModal()"
    ></app-modal-coupon>
</div>
