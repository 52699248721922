import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent implements OnInit {
  @Input() placeholder = '';
  @Input() oldKeyword: string | undefined;
  @Output() searchChange = new EventEmitter<string>();
  value = '';

  ngOnInit(): void {
    if (this.oldKeyword) {
      this.value = this.oldKeyword
    }
  }

  onSearch(): void {
    this.searchChange.emit(this.value);
  }

  handleSearchClick(): void {
    this.onSearch();
  }

  onClearSearchValue(): void {
    this.value = '';
    this.onSearch(); // Clear search query
  }
}
