import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ProductParams,
    ProductStockist,
} from '@core/models/retailer/product.model';
import { AzureBlobService } from '@core/services/retailer/azure-blob.service';
import { CmsService } from '@core/services/retailer/cms.service';
import { TranslationService } from '@modules/i18n';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { FilterEvent } from '../all-product/all-product.component';

@Component({
    selector: 'app-show-case-detail',
    templateUrl: './show-case-detail.component.html',
    styleUrls: ['./show-case-detail.component.scss'],
})
export class ShowCaseDetailComponent implements OnInit {
    params: ProductParams = {
        limit: 50,
        page: 1,
    };
    productList: ProductStockist[] = [];
    image_Logo_Url$ = new BehaviorSubject<ArrayBuffer | null>(null);
    showCaseNumber = 0;
    showNotFoundIcon = false;
    isOpenFilterModal = false;
    hasMoreData = true;
    total = 0;
    filterTotal = 0;
    totalOrderPage = 0;
    currentLang = 'th'
    isLoading = false

    constructor(
        private route: Router,
        private activeRoute: ActivatedRoute,
        private cmsService: CmsService,
        private azureBlobService: AzureBlobService,
        private spinner: NgxSpinnerService,
        private translationService: TranslationService,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.currentLang = this.translate.currentLang
        this.activeRoute.params.subscribe((params) => {
            this.showCaseNumber = params['show-case-number'];
        });
        const filterHistory = localStorage.getItem('oldFilter')
         if (filterHistory) {
            const totalSelected = 0
            const filter: FilterEvent = {
                params: {
                    page: 1,
                    limit: 50,
                    datatable: true,
                },
                totalSelected: 0
            }
            filter.totalSelected = totalSelected
            this.filterProduct(filter)
        } else {
            this.handleSearch();
        }
    }

    filterProduct(filterEvent: FilterEvent) {
        this.filterTotal = filterEvent.totalSelected;
        const keywords = this.params.keywords;
        this.params = {
            keywords,
            ...filterEvent.params,
        };
        this.productList = [];
        this.hasMoreData = true;
        const filterHistory = localStorage.getItem('oldFilter')
        if (filterHistory) {
            const filterJson: ProductParams = JSON.parse(filterHistory)
            const filter: FilterEvent = {
                params: {
                    page: 1,
                    limit: 50,
                    datatable: true,
                },
                totalSelected: 0
            }
            if (filterJson.keywords) {
                this.params.keywords = filterJson.keywords
                filter.params.keywords = filterJson.keywords
            }
        }
        this.handleSearch();
    }

    search(query: string): void {
        this.params = {
            ...this.params,
            limit: 50,
            page: 1,
            datatable: true,
            keywords: query,
        };
        this.productList = [];
        this.handleSearch();
    }

    handleSearch() {
        this.isLoading = true
        this.spinner.show('spinner');
        this.cmsService
            .getShowCaseProduct(this.showCaseNumber, this.params)
            .subscribe({
                next: (res) => {
                    this.total = res.total;
                    if (Array.isArray(res.data)) {
                        this.totalOrderPage = res.totalPage;
                        if (res.data.length === 0) {
                            this.hasMoreData = false;
                            this.spinner.hide('spinner');
                            this.isLoading = false
                        } else {
                            const newProducts = res.data;
                            const existingIds = this.productList.map(
                                (item) => item.id
                            );
                            const uniqueNewProducts = newProducts.filter(
                                (item) => !existingIds.includes(item.id)
                            );

                            this.productList.push(...uniqueNewProducts);
                            this.spinner.hide('spinner');
                            this.isLoading = false
                        }
                        this.showNotFoundIcon = this.productList.length === 0;
                    }
                },
                error: () => {
                    this.showNotFoundIcon = this.productList.length === 0;
                    this.spinner.hide('spinner');
                    this.isLoading = false
                },
            });
    }

    navigateBack(): void {
        this.route.navigate(['/products']);
    }

    showNotFoundMessage(): boolean {
        return this.showNotFoundIcon && this.productList.length <= 0;
    }

    @HostListener('window:scroll', ['$event'])
    onScroll() {
        if (window.innerHeight + 10 + window.scrollY >= document.body.offsetHeight && !this.isLoading && this.params?.page && this.params?.page < this.totalOrderPage) {
            this.params.page++;
            this.handleSearch();
        }
    }

    // onScroll() {
    //     if (this.params?.page && this.params?.page < this.totalOrderPage) {
    //         this.params.page++;
    //         this.handleSearch();
    //     }
    // }
}
