/* eslint-disable @typescript-eslint/no-explicit-any */
export class PreviewOrder {
  retailerAddress: string;
  summaryAmount: number;
  orders: OrderDetail[];

  constructor(data: any) {
    this.retailerAddress = data.retailerAddress;
    this.summaryAmount = data.summaryAmount;
    this.orders = data.orders?.map((x: OrderDetail) => new OrderDetail(x));
  }
}

export class OrderDetail {
  amount: number;
  discount: number;
  shippingCost: number;
  netAmount: number;
  stockistName: string;
  items: PurchaseOrderItem[];

  constructor(data: OrderDetail) {
    this.amount = data.amount;
    this.discount = data.discount;
    this.shippingCost = data.shippingCost;
    this.netAmount = data.netAmount;
    this.stockistName = data.stockistName;
    this.items = data.items?.map(
      (x: PurchaseOrderItem) => new PurchaseOrderItem(x)
    );
  }
}

export class PurchaseOrderItem {
  coverImagePathThump: string;
  productName: string;
  productNameEn: string;
  unitAmount: number;
  imagePath: string;
  code: string;
  name: string;
  amount: number;
  quantity: number;
  promotionDiscount: number;

  constructor(data?: any) {
    this.coverImagePathThump = data.coverImagePathThump;
    this.productName = data.productName;
    this.productNameEn = data.productNameEn;
    this.unitAmount= data.unitAmount;
    this.imagePath = data?.imagePath || '';
    this.code = data?.code || '';
    this.name = data?.name || '';
    this.quantity = data?.quantity || 0;
    this.amount = data?.amount || 0;
    this.promotionDiscount = data?.promotionDiscount || 0
  }
}
export class PurchaseOrderActions {
  id: number;
  status: string;
  actionRequest: string;
  actionDate: string;
  actionBy: string;
  actionByID: number;
  actionReasons: string;
  constructor(data: PurchaseOrderActions) {
    this.id  = data.id;
    this.status = data.status;
    this.actionRequest = data.actionRequest;
    this.actionDate = data.actionDate;
    this.actionBy = data.actionBy;
    this.actionByID = data.actionByID;
    this.actionReasons = data.actionReasons;
  }
}

