<div class="background-order-confirm margin-btm" *ngIf="data">
    <div class="d-flex justify-content-between fs-5 background-order-head">
        <div>
            <p data-cy="label-page-info" class="me-auto fw-semibold text-head-first">
              {{ "TITLE.REQUEST_CANCEL_SEND" | translate }}
            </p>
            <p class="fw-ligh text-head-mid">{{ "TITLE.NUMBER" | translate }} {{data.id}}</p>
            <p class="fw-light text-head-last">
              {{ "TITLE.WANT_CANCEL_AT" | translate }} {{data.cancelRequestedDate| displayDate}}
            </p>
        </div>
        <div>
            <img class="" src="assets/images/Order Cancel.svg" width="52px" height="52px" alt="Not found" />
        </div>
    </div>
    <div class="fs-sm d-flex align-items-center gap-2"
        style="background-color: #EFEFEF;padding: 10px;border-left: 4px solid #505050; color: #505050;">
        <img src="assets/icons/receipt-cancle.svg" width="16px" height="16px" />
        <p class="remove-padding ">
          {{ "TITLE.CANCEL_ALERT" | translate }}
        </p>

    </div>
    <div class="d-flex gap-2 fs-6 py-2 text-wrap align-item-center px-3">
        <img src="\assets\icons\Store_Icon.svg" alt="notFound" />
        <p class="store-name">{{data.retailerName}}</p>
    </div>
    <div class="bottom-border"></div>

    <div class="d-flex gap-2 text-wrap align-items-start px-3 my-2" *ngFor="let productData of data.items">
        <img *ngIf="productData.quantity !== 0" class="coverImg" src="{{productData.coverImagePathThump}}" alt="notFound"
            (error)="handleImageError($event)" />
        <div *ngIf="productData.quantity !== 0" class="w-100">
            <p class="product-name">
                {{productData.productName}}
            </p>
            <div class="d-flex justify-content-between price-row">
                <span>x{{productData.quantity}}</span>
                <!-- <span>{{ formatCurrency(productData.amount) }}</span> -->
                <span>{{ productData.amount | currency: 'THB' : 'symbol-narrow' }}</span>
            </div>
        </div>
    </div>
    <div class="background-gift-item px-3" *ngIf="data.promotions.length && isPurchaseOrderHaveGiftPromotion(data)">
        <div class="topic-gift pb-3">{{ "TITLE.GIFT" | translate }}</div>
        <div class="gift-section">
            <div class="gift-item" *ngFor=" let promotion of data.promotions">
                <div *ngIf="promotion.promotionType === 'Gift'" class="d-flex gap-1 align-items-center">
                    <img alt="Product image" class="product_image"
                        [src]="promotion.giveAway.coverImagePathThump"
                        onerror="this.src='assets/images/image_error.jpg'" />
                    <div>{{promotion.giveAway.name}}</div>
                </div>
                <div *ngIf="promotion.promotionType === 'Gift'" class="d-flex align-items-center">{{promotion.quantity}} {{ "TITLE.ITEM" | translate }}</div>
            </div>
        </div>
    </div>


    <div class="px-3 mt-3">
        <div class="d-flex justify-content-between fw-rg fs-sm pt-1">
            <div>{{ "PAYMENT.TOTAL_ALL" | translate }} ({{totalQuantity()}} {{ "TITLE.ITEM" | translate }})</div>
            <div>{{ data.amount | currency: 'THB' : 'symbol-narrow' }}</div>
        </div>
        <div class="d-flex justify-content-between fw-rg fs-sm pt-1">
            <div>{{ "PAYMENT.PROMOTION_COUPON" | translate }}</div>
            <div>{{ data.discount | currency: 'THB' : 'symbol-narrow' }}</div>
        </div>
        <div class="d-flex justify-content-between fw-rg fs-sm pt-1">
            <div>{{ "PAYMENT.SHIPPING_COST" | translate }}</div>
            <div>{{ data.shippingCost | currency: 'THB' : 'symbol-narrow' }}</div>
        </div>
        <div class="d-flex justify-content-between fs-md fw-semibold py-3">
            <div>{{ "PAYMENT.NET_TOTAL" | translate }}</div>
            <div>
                {{ data.netAmount | currency: 'THB' : 'symbol-narrow' }}
            </div>
        </div>
    </div>

</div>

<div id="footer-page" class="footer-position">
    <div class="d-flex flex-column w-100 px-3 pt-3 background-order-confirm">
        <!-- <div class="d-flex flex-column w-100 px-3 pt-3  footer-top-border background-order-confirm"> -->
        <div class="d-flex flex-column gap-3 mb-3">
            <button type="button" class="btn detail-order-btn" (click)="navigateToOrderHistoryDetail()">
              {{ "TITLE.ORDER_DETAIL" | translate }}
            </button>
            <button type="button" class="btn btn-secondary-button" (click)="navigateToLanding()">
              {{ "ACTION.BACK_PAGE_MAIN" | translate }}
            </button>
        </div>

    </div>
</div>
