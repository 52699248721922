<div class="container">
    <div class="cancelled-header" [attr.data-cy]="'cancelled-status-' +
        (cancelledInfo.status.toLocaleLowerCase() === 'Confirmorder'.toLocaleLowerCase() ? 'confirmed' :
        (cancelledInfo.status.toLocaleLowerCase() === 'Pending'.toLocaleLowerCase() ? 'pending' : 'unknow')) + '-message'">
        {{getCancelledLabel(cancelledInfo.status).split("|")[0]}}
        <br>
        {{getCancelledLabel(cancelledInfo.status).split("|")[1]}}
    </div>

    <div class="cancelled-message-warning" *ngIf="cancelledInfo.status.toLocaleLowerCase() === 'confirmed'"
        [attr.data-cy]="'cancelled-'+cancelledInfo.status+'-warning-message'">
        {{ "CANCEL_TITLE.TILTE1" | translate }}
        <br>
        {{ "CANCEL_TITLE.TILTE2" | translate }}
    </div>
    <div class="button-container">
        <button class="home-navigation-button" routerLink="/retailer/"
            data-cy="cancelled-navigate-to-retailer-product-button">{{ "ACTION.BACK_PAGE_MAIN" | translate }}</button>
        <button class="order-history-navigation-button" routerLink="/retailer/order-history"
            data-cy="cancelled-navigate-to-retailer-order-history-button">{{ "ACTION.BACK_PAGE_HISTORY" | translate }}</button>
    </div>
</div>
