import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CategoryList } from "@core/models/retailer/category.model";

@Component({
    selector: "app-card-category",
    templateUrl: "./card-category.component.html",
    styleUrls: ["./card-category.component.scss"],
})
export class CardCategoryComponent {
    @Input() item: CategoryList | undefined;
    @Output() clickCategory: EventEmitter<number> = new EventEmitter<number>()

    onClickCategory(categoryId:number) {
        this.clickCategory.emit(categoryId)
    }
}