import { Component, Input } from "@angular/core";
import { PaymentInfo } from "@core/models/shares/modal.model";

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent {

    @Input() paymentInfo: PaymentInfo;

    constructor() {
        this.paymentInfo = {
            totalQuantity: 0.00,
            totalAmount: 0.00,
            couponDiscount: 0.00,
            promotionDiscount: 0.00,
            discount: 0.00,
            shippingCost: 0.00,
            netAmount: 0.00,
        };
    }
}