<div *ngIf="orderDetail">
    <nav class="navigate-bar">
        <div class="back-container" (click)="navigateToOrderHistory()">
            <i class="fa-solid fa-arrow-left" aria-hidden="true"></i>
            <label for="backforward">{{ "ACTION.BACK_PAGE" | translate }}</label>
        </div>
    </nav>

    <div class="bg-gradient-order-confirmed mt-2">
        <div class="px-3 mb-1 d-flex justify-content-between align-items-center">
            <div>
                <p class="fs-sm" style="color: #8E8E8E;">{{ "TITLE.NUMBER" | translate }}</p>
                <p class="fw-bold">{{ orderDetail.id }}</p>
            </div>
            <div class="fs-sm fw-semibold order-status text-center" [ngClass]="orderDetail.status.toLocaleLowerCase()">
              {{ "TITLE.CONFIRMED" | translate }}
            </div>
        </div>
        <div class="pb-3 px-3">
            <div class="d-flex flex-column">
                <div class="d-flex justify-content-start align-items-center">

                    <div *ngIf="orderDetail?.status" class="fw-normal"
                        [attr.data-cy]="orderDetail.status.toLowerCase() + '-date'">
                        {{ "TITLE.CONFIRM_AT" | translate }}
                        {{ orderDetail.confirmedDate | displayDate }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="orderDetail.cancelRequest" class="fs-sm mb-3 d-flex align-items-center gap-2"
        style="background-color: #FFE6E6;padding: 10px;border-left: 4px solid #F43A29; color: #F43A29;">
        <img src="assets/icons/x-square.svg" width="16px" height="16px" />
        <p>
          {{ "TITLE.CANCEL_NOT_APPROVE" | translate }}
        </p>

    </div>

    <div class="px-3">
        <div class="d-flex justify-content-between fw-light">
            <div class="order-history-detail-info d-flex align-items-center">
                <i class="fa-solid fa-calendar"></i>
                <div class="ml-2">
                    <div class="order-text-header fs-sm">{{ "TITLE.EXPECTED_DELIVERY_DATE" | translate }}</div>
                    <div class="fs-6 fw-normal order-text-message">
                        {{orderDetail.deliveryDate| displayDeliveryDate}}, {{formatTime(orderDetail.officeTime)}}
                    </div>
                    <div class="d-flex align-items-baseline gap-2 ms-2 mt-2" *ngIf="checkLateDelivered()">
                        <i class="fa-solid fa-circle-exclamation mt-1" style="color: #F43A29;"></i>
                        <p class="fs-sm" style="color: #F43A29;">{{ "TITLE.ORDER_LATE" | translate }} {{appName}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="px-3">
        <div class="d-flex justify-content-between fw-light">
            <div class="order-history-detail-info d-flex align-items-center">
                <i class="fa-solid fa-truck"></i>
                <div class="ml-2">
                    <div class="order-text-header fs-sm pt-1">{{ "TITLE.DELIVER_FROM" | translate }}</div>
                    <div class="fs-6 fw-normal order-text-message">
                        {{orderDetail.stockistName}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="px-3">
        <div class="mb-4">
            <app-ship-to-address [name]="orderDetail.retailerName" [address]="getAddress()"></app-ship-to-address>
            <div class="border-line-bottom"></div>
            <div>
                <div class="">
                    <div class="fs-6 fw-semibold product-list-label pb-3">
                        <p>{{ "TITLE.PRODUCT" | translate }}</p>
                    </div>
                </div>
                <div class="">
                    <app-show-product-list [products]="orderDetail.items"></app-show-product-list>
                    <div class="background-gift-item"
                        *ngIf="orderDetail.promotions.length && isPurchaseOrderHaveGiftPromotion(orderDetail)">
                        <div class="topic-gift pb-3">{{ "TITLE.GIFT" | translate }}</div>
                        <div class="gift-section">
                            <div class="gift-item" *ngFor=" let promotion of orderDetail.promotions">
                                <div *ngIf="promotion.promotionType === 'Gift'" class="d-flex gap-1 align-items-center">
                                    <img alt="Product image" class="product_image"
                                        [src]="promotion.giveAway.coverImagePathThump"
                                        onerror="this.src='assets/images/image_error.jpg'" />
                                    <div>{{promotion.giveAway.name}}</div>
                                </div>
                                <div *ngIf="promotion.promotionType === 'Gift'" class="d-flex align-items-center">{{promotion.quantity}} {{ "TITLE.ITEM" | translate }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <app-payment [paymentInfo]="{
                        totalQuantity: sumTotalQuatity(),
                        totalAmount: orderDetail.amount,
                        discount: orderDetail.discount,
                        promotionDiscount: orderDetail.promotionDiscount,
                        couponDiscount: orderDetail.couponDiscount,
                        shippingCost: orderDetail.shippingCost,
                        netAmount: orderDetail.netAmount
                    }"></app-payment>
            </div>
            <div *ngIf="orderDetail.refOrderId"
                class="my-3 change-order-tab d-flex justify-content-between align-items-center p-2">
                <div class="d-flex align-items-center gap-2">
                    <i class="fa-solid fa-circle-exclamation"></i>
                    <p class="fs-xs" style="color: #505050;">{{ "TITLE.ORDER_CHANGE" | translate }}</p>
                </div>
                <button class="btn-old-order fs-sm fw-md" (click)="navigateToOldOrder()">{{ "TITLE.VIEW_ORDER" | translate }}  <i
                        class="fa-solid fa-arrow-right" aria-hidden="true"></i></button>
            </div>
            <div class="fs-sm mb-3 d-flex align-items-center gap-2"
                style="background-color: #EFEFEF;padding: 10px;border-left: 4px solid #505050; margin: 0 -16px; color: #505050;">
                <img src="assets/icons/receipt-cancle.svg" width="16px" height="16px" />
                <p>
                  {{ "TITLE.CANCEL_ALERT2" | translate }}
                </p>

            </div>
            <div class="d-flex flex-column gap-3 mb-3">
                <button type="button" class="btn btn-outline-danger fw-semibold"
                    style="width: 100% !important; align-self: center" data-cy="cancel-order-btn"
                    (click)="onClickOpenCancelModal()">
                    {{ "TITLE.WANT_CANCEL_PURCHASE" | translate }}
                </button>
                <button type="button" class="btn btn-secondary-button fw-semibold"
                    style="width: 100% !important; align-self: center" data-cy="confirm-order-btn"
                    (click)="addItemToCart()">
                    {{ "TITLE.ORDER_AGAIN" | translate }}
                </button>
            </div>
        </div>
    </div>
    <app-reason-cancellation-modal *ngIf="openCancelModal" [isOpenModal]="openCancelModal"
        [orderNumber]="orderDetail.id" (isOpenModalChange)="onClickCloseModal()"
        [currentOrderStatus]="orderDetail.status" />

    <app-reorder-modal *ngIf="openReorderModal" [isOpenModal]="openReorderModal"
        [orderNumber]="orderDetail.id" [products]="reOrderProducts" (isOpenModalChange)="onClickCloseModal()"
        [currentOrderStatus]="orderDetail.status" />
</div>
