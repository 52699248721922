import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-ship-to-address',
    templateUrl: './ship-to-address.component.html',
    styleUrls: ['./ship-to-address.component.scss'],
})
export class ShipToAddressComponent {
    @Input() name: string | undefined = '';
    @Input() address: string | undefined = '';
    @Input() isShowLocation = false;

    constructor(
      private translate: TranslateService
    ) { }


    mapImage = 'assets/images/map.png';
}
