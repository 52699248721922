<div class="container mt-3">
    <div class="d-flex align-items-center mb-3">
        <i class="fa-solid fa-angle-left mb-2" routerLink="/retailer/products"></i>
        <div class="fs-4 mx-auto" data-cy="order-number">{{ "TITLE.PURCHASE_CANCEL" | translate }} {{cancellationInfo.purchaseOrderNumber}}</div>
    </div>
    <div class="fs-5 mb-5" data-cy="stockist-name">{{ "TITLE.FROM_STORE" | translate }} {{cancellationInfo.name}}</div>
    <form>
        <p class="fs-6 fw-semibold">{{ "TITLE.CANCEL_REASON" | translate }}*</p>
        <div *ngIf="cancellationInfo.status === 'Pending'; else ConfirmOrder">
            <div class="form-check my-3 mx-3" *ngFor="let reason of pendingReasonsText; let i = index">
                <input class="form-check-input" type="checkbox" [id]="'reason' + (i + 1)" (change)="onClickCheckbox(i)"
                    [attr.data-cy]="'check-box-reason-' + (i + 1)">
                <label class="form-check-label" [for]="'reason' + (i + 1)">
                    {{reason}} <span class="text-muted" *ngIf="i === pendingReasonsText.length - 1"> {{ "TITLE.PLEASE_SPECIFY" | translate }}</span>
                </label>
            </div>
        </div>
        <ng-template #ConfirmOrder>
            <div class="form-check my-3 mx-3" *ngFor="let reason of confirmReasonsText; let i = index">
                <input class="form-check-input" type="checkbox" [id]="'reason' + (i + 1)" (change)="onClickCheckbox(i)"
                    [attr.data-cy]="'check-box-reason-' + (i + 1)">
                <label class="form-check-label" [for]="'reason' + (i + 1)">
                    {{reason}} <span class="text-muted" *ngIf="i === confirmReasonsText.length - 1"> {{ "TITLE.PLEASE_SPECIFY" | translate }}</span>
                </label>
            </div>
        </ng-template>
    </form>
    <div class="form-floating mb-3">
        <p for="additionalNote" class="fs-6 fw-semibold">{{ "TITLE.ADDITIONAL_REASON" | translate }}</p>
        <textarea class="form-control placeholder-text-area" id="additionalNote" placeholder="{{ 'PLACEHOLDER.ADDITIONAL' | translate }}"
            style="height: 8rem" [(ngModel)]="otherReason" (change)="updateFinalReason()"
            data-cy="text-other-reason"></textarea>
    </div>
    <div id="footer-page" class="d-flex flex-column fixed-bottom mx-4 mr-4 mb-4">
        <button type="submit" type="button" class="btn btn-primary mr-4 cancel-order-btn" data-cy="cancel-order-button"
            [disabled]="!isFormValid" (click)="cancelOrder()">{{ "ACTION.CONFIRM_CANCELLATION" | translate }}</button>
    </div>
</div>
